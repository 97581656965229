import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { EtatsService } from '../service/EtatsService';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
export class EtatHistorique extends Component {
    constructor() {
        super();
        this.state = {
            dataEtatsValue: [],
            etat: null,

        };
        this.etatsService = new EtatsService()
        this.onEtatChange = this.onEtatChange.bind(this);

    }

    componentDidMount() {

        this.etatsService.getAllEtat().then(data => this.setState({ dataEtatsValue: data }));      
    }

    onEtatChange(event) {
        this.dt.filter(event.value, 'etat', 'equals');
        this.setState({etat: event.value});
    }

    render() {
        var header = <div style={{'textAlign':'left'}}>
        <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
        <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
    </div>;

let etats = [
    {label: 'Tous', value: null},
    {label: 'EN MARCHE', value: 'EN MARCHE'},
    {label: 'ARRET', value: 'ARRET'}   
];

let etatFilter = <Dropdown style={{width: '100%'}}
value={this.state.etat} options={etats} onChange={this.onEtatChange}/>
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>EtatHistorique</h1>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataEtatsValue} paginator={true} rows={10} header={header}
                        globalFilter={this.state.globalFilter} emptyMessage="No records found">
                        <Column field="date" header="date" filter={true} />
                        <Column field="etat" header="etat" filter={true} filterElement={etatFilter} />
                        <Column field="pression" header="pression" filter={true} />
                        <Column field="distance" header="distance" filter={true} />
                        <Column field="mouvement" header="mouvement" filter={true} />
                        <Column field="batterie" header="batterie" filter={true} />
                        <Column field="temp" header="temp" filter={true} />
                        <Column field="buse" header="buse" filter={true} />
                        <Column field="mouvement" header="mouvement" filter={true} />
                    </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}