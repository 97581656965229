import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';
import { InputText } from 'primereact/inputtext';

class InputTextI18n extends Component {
    render() {
        return (
            <FormattedMessage id={this.props.i18nId} defaultMessage={this.props.defaultMessage}>
                {formattedMessage =>
                    <InputText placeholder={formattedMessage}
                        key={this.props.key}
                        name={this.props.name}
                        value={this.props.value}
                        onChange={this.props.onChange} />
                }
            </FormattedMessage>
        );
    }
}

export default InputTextI18n;