import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GatewayService from '../../service/GatewayService';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

class Gateways extends Component {

    constructor() {
        super();
        this.state = {
            gateways: [],
            gateway: null,
            displayDialog: false,
            selectedGateway: null
        };
        this.gatewayService = new GatewayService();
        this.addNew = this.addNew.bind(this);
        this.onSave = this.onSave.bind(this);
        this.findSelectedCarIndex = this.findSelectedCarIndex.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCarSelect = this.onCarSelect.bind(this);
        this.updateProperty = this.updateProperty.bind(this);
    }

    componentDidMount() {
        this.gatewayService.list()
            .then((result) => {
                this.setState({ gateways: result });
            })
            .catch((error) => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    addNew() {
        this.newGateway = true;
        this.setState({
            gateway: { phone: '', sn: '' },
            displayDialog: true
        });
    }

    onSave() {
        this.gatewayService.save(this.state.gateway)
            .then((result) => {
                let gateways = [...this.state.gateways];
                if (this.newGateway) {
                    gateways.push(this.state.gateway);
                } else {
                    gateways[this.findSelectedCarIndex()] = this.state.gateway;
                }
                this.setState({ gateways: gateways, selectedCar: null, car: null, displayDialog: false });
            })
            .catch((error) => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });

    }

    findSelectedCarIndex() {
        return this.state.gateways.indexOf(this.state.selectedGateway);
    }

    onDelete() {
        let index = this.findSelectedCarIndex();
        this.setState({
            gateways: this.state.gateways.filter((val, i) => i !== index),
            selectedCar: null,
            car: null,
            displayDialog: false
        });
    }

    onCarSelect(e) {
        this.newGateway = false;
        this.setState({
            displayDialog: true,
            gateway: Object.assign({}, e.data)
        });
    }

    updateProperty(property, value) {
        let gateway = this.state.gateway;
        gateway[property] = value;
        this.setState({ gateway: gateway });
    }

    render() {
        let footer = <div className="p-clearfix" style={{ width: '100%' }}>
            <Button style={{ float: 'left' }} label="Add" icon="pi pi-plus" onClick={this.addNew} />
        </div>;

        let deleteButton = <Button label="Delete" icon="pi pi-times" onClick={this.onDelete} disabled />

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Save" icon="pi pi-check" onClick={this.onSave} />
        </div>;
        return (
            <div>
                <Messages ref={(el) => this.messages = el} />
                <DataTable value={this.state.gateways} paginator={true} rows={15} header="Gateways" footer={footer}
                    selectionMode="single" selection={this.state.selectedGateway} onSelectionChange={e => this.setState({ selectedGateway: e.value })}
                    onRowSelect={this.onCarSelect}>
                    <Column field="phone" header="Phone Number" sortable={true} />
                    <Column field="sn" header="SN" sortable={true} />
                </DataTable>
                <Dialog visible={this.state.displayDialog} style={{ width: '300px' }} header="Gateway Details" modal={true} footer={dialogFooter} onHide={() => this.setState({ displayDialog: false })}
                    blockScroll={false}>
                    {
                        this.state.gateway &&
                        <div className="p-grid p-fluid">
                            <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="phone">Phone Number</label></div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                                <InputText id="phone" onChange={(e) => { this.updateProperty('phone', e.target.value) }} value={this.state.gateway.phone} />
                            </div>

                            <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="sn">SN</label></div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                                <InputText id="sn" onChange={(e) => { this.updateProperty('sn', e.target.value) }} value={this.state.gateway.sn} />
                            </div>
                        </div>
                    }
                </Dialog>
            </div>
        );
    }
}

export default Gateways;