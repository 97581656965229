import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import PhoneNumberService from '../../service/PhoneNumberService';

const SMS_IN_PREFIX = 'https://api.ri-500.ca/ri/v1/sms/twilio/in/';

class IncomingPhoneNumbers extends Component {

    constructor() {
        super();
        this.state = {
            phones: [],
            phoneFilter: '',
            totalRecords: 300,
            page: 0,
            hasNextPage: false,
            hasPreviousPage: false,
            loading: true
        };
        this.rows = 10;
        this.phoneNumberService = new PhoneNumberService();
        this.loadPage = this.loadPage.bind(this);
    }

    componentDidMount() {
        this.loadPage(0);
    }

    loadPage(page) {
        this.setState({
            page: page,
            loading: true
        });

        this.phoneNumberService.getPhonesPage(this.rows, page, this.state.phoneFilter)
            .then(phones => {
                this.setState({
                    page: page,
                    phones: phones.instances ? phones.instances : [],
                    loading: false,
                    hasPreviousPage: phones.previousPageUrl ? true : false,
                    hasNextPage: phones.nextPageUrl ? true : false
                });
            }).catch(e => {
                alert('error');
                this.setState({
                    loading: false
                });
            });
    }

    serialNumberTemplate(rowData, column) {
        if (rowData.smsUrl.startsWith(SMS_IN_PREFIX)) {
            return <div style={{ textAlign: 'center' }}>
                {rowData.smsUrl.slice(SMS_IN_PREFIX.length)}
                <Button label="Edit" style={{ marginLeft: '5px' }} onClick={() => this.activateWebhook(rowData)} className="p-button-rounded" />
            </div>;
        } else {
            return <div style={{ textAlign: 'center' }}><Button label="Activate Webhook" onClick={() => this.activateWebhook(rowData)} className="p-button-rounded" /></div>;
        }
    }

    activateWebhook(phone) {
        let sn = prompt("Enter the serial number (SN)");
        if (sn) {
            this.setState({
                loading: true
            });
            this.phoneNumberService.updatePhoneSmsUrl(phone.sid, SMS_IN_PREFIX + sn)
                .then(result => this.loadPage(this.state.page));
        }
    }

    render() {
        const pageFooter = (
            <div>
                <Button label="Previous" onClick={() => this.loadPage(this.state.page - 1)} disabled={!this.state.hasPreviousPage} />
                <Button label="Next" style={{ marginLeft: '5px' }} onClick={() => this.loadPage(this.state.page + 1)} disabled={!this.state.hasNextPage} />
            </div>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <p className="p-float-label">
                            <InputText id="phoneFilter" value={this.state.phoneFilter} onChange={event => this.setState({ phoneFilter: event.target.value })} />
                            <label htmlFor="phoneFilter">Phone Filter</label>
                            <Button label="Filter" style={{ marginLeft: '5px' }} onClick={() => this.loadPage(0)} />
                        </p>
                    </div>
                    <div className="card">
                        <div>
                            <DataTable value={this.state.phones} loading={this.state.loading} footer={pageFooter}>
                                <Column field="phoneNumber" header="Phone Number" />
                                <Column body={this.serialNumberTemplate.bind(this)} header="Serial Number" />
                                <Column field="smsUrl" header="SMS URL" />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IncomingPhoneNumbers;