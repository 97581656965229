/* global Plotly:true */
import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);

class PluvioAllInOneChart extends React.Component {

    constructor(props) {
        super(props);

        // compute the dose and display the pluviometry chart
        let dose = props.pathObj.computeDose();

        // Dose
        var trace1 = {
            x: dose.endPointDistance,
            y: dose.mm,
            line: { shape: 'spline' },
            type: 'scatter',
            mode: 'lines',
            name: 'Dose(mm)  <b>[avg: ' + dose.avgDose + ']</b>',
            line: {
                width: 2,
                color: 'rgb(0, 94, 184)', // BLUE
            }
        };

        // Pressure
        var trace2 = {
            x: props.pathObj.endPointDistance,
            y: props.pathObj.pressure,
            line: { shape: 'spline' },
            type: 'scatter',
            mode: 'lines',
            name: 'Pressure(psi)  <b>[avg: ' + dose.avgPressure + ']</b>',
            //yaxis: 'y2',
            line: {
                width: 2,
                color: 'rgb(255, 100, 0)', // ORANGE
            }
        }

        // Speed
        var trace3 = {
            x: props.pathObj.endPointDistance,
            y: props.pathObj.speed,
            line: { 'shape': 'spline', 'smoothing': 2.0 },
            type: 'scatter',
            mode: 'lines',
            name: 'Speed(m/h)  <b>[avg: ' + dose.avgSpeed + ']</b>',
            //yaxis: 'y3',
            line: {
                width: 2,
                color: 'rgb(0, 128, 0)', // GREEN
            }
        };

        var allinone_data = [trace1, trace2, trace3];

        const plotJSON = {
            data: allinone_data,
            layout: {
                showlegend: true,
                legend: {
                    x: 1,
                    xanchor: 'left',
                    y: 0.5
                },
                title: '<b>Pluviométrie vs Position</b><br><br>Volume: ' + (dose.vol) + 'm³',
                height: 1000,
                xaxis: {
                    title: "position (m)",
                    autorange: 'reversed',
                    domain: [0, 0.95]
                },

                // Dose axis
                yaxis: {
                    //title: "mm",
                    side: 'left',
                    rangemode: 'tozero',
                    autorange: true,
                    //position: 0.05
                    position: 0.96
                },
                // Pressure axis
                /*
                yaxis2: {
                    title: "psi",
                    overlaying: 'y',
                    side: 'right',
                    rangemode: 'tozero',
                    autorange: true,
                    position: 0.91
                },		
                // Speed axis
                yaxis3: {
                    title: "m/h",
                    overlaying: 'y',
                    side: 'right',
                    rangemode: 'tozero',
                    autorange: true,
                    position: 0.95
                },
                */

                annotations: [

                    // START indication
                    {
                        x: dose.startPos,
                        y: 0,
                        xref: 'x',
                        yref: 'y',
                        text: 'Start Position: '+dose.startPos,
                        showarrow: true,
                        arrowhead: 3,
                        ax: 50,
                        ay: 50
                    },
                    // END indication
                    {
                        x: -props.pathObj.remDistance,
                        y: 0,
                        xref: 'x',
                        yref: 'y',
                        text: 'End Position',
                        showarrow: true,
                        arrowhead: 3,
                        ax: 50,
                        ay: 50
                    },
                    // Total Volume (within graph)
                    /*
                    {
                        x: dose.startPos/2,
                        text: 'Volume: '+(dose.vol)+'m³',
                        showarrow: false,
                        font: {
                            color: "black",
                            size: 30
                        }
                        
                    },
                    */

                ]
            }
        };

        this.state = {
            json: plotJSON
        };
    }

    render() {
        return (<div>
            <Plot
                data={this.state.json.data}
                layout={this.state.json.layout}
                style={{ width: "100%", height: "100%" }}
                config={{ displayModeBar: true }}
            />
        </div>);
    }
}

export default PluvioAllInOneChart;