import React, { Component } from 'react';
import appUtil from '../utils/appUtil';
import { TrajetsService } from '../service/TrajetsService';
import { PasserellesService } from '../service/PasserellesService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Messages } from 'primereact/messages';
import config from '../config/config';
import { Chart } from 'primereact/chart';
import logger from '../config/logger';
import { Dropdown } from 'primereact/dropdown';
import ButtonI18n from './i18n/ButtonI18n';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import pluvio from './src/pluvio';
import PluvioAllInOneChart from './PluvioAllInOneChart';
import PluvioAllInOneChartTime from './PluvioAllInOneChartTime';
import { dsp } from './src/dsp.js';
import auth from '../utils/auth';
import LoadingSpinner from './LoadingSpinner';
import { Link } from 'react-router-dom';

const messages = defineMessages({
    errordata: {
        id: "error.nodatafordate",
        defaultMessage: "List of Gateways"
    },
    errorinput: {
        id: "error.input",
        defaultMessage: "Mandatory fields missing"
    },
    errorloading: {
        id: "error.loading",
        defaultMessage: "Loading. Please wait..."
    },
    selectgateway:
    {
        id: "state.selectgateway",
        defaultMessage: "Select a gateway"
    },
    track:
    {
        id: "track.track",
        defaultMessage: "Track"
    },
    errorNozzle: {
        id: "error.no.matching.nozzle",
        defaultMessage: "No matching nozzle found"
    }

});

class Trajet extends Component {

    constructor(props) {
        super(props);
        let today = new Date();
        // set default mode => The smaller the number, the better the quality but more processing
        const mode = 0;


        this.state = {
            trajets: [],
            selectedTrajet: [],
            invalidDates: [today],
            passerelle: '',
            presstions: {},
            speeds: {},
            date: null,
            colorList: ['#42A5F5', '#66BB6A', '#42A5F5', '#1dcbb3'],
            passerelles: [],
            isMobile: false,
            isMap: false, isForm: true, isDataTable: true, isGraph: false, isSpeedGraph: false, isRainfallGraph: false, isReturn: false,
            pathObj: {},
            mode: mode,
            calendarPath: []
        };

        this.passerellesService = new PasserellesService();
        this.trajetsService = new TrajetsService();
        this.loadTrajets = this.loadTrajets.bind(this);
        this.afficherCarte = this.afficherCarte.bind(this);
        this.returnAffichage = this.returnAffichage.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.nameBySerial = this.nameBySerial.bind(this);
        this.afficherPluviometrie = this.afficherPluviometrie.bind(this);
        this.handleChangeGateway = this.handleChangeGateway.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
        this.theMap = null;
        this.markers = [];
        this.bounds = null;
    }

    handleChangeGateway(event) {
        let now = new Date();
        let startDate = new Date(now.getFullYear() - 1, 0, 1);
        this.trajetsService.getStartPathInRange(event.value.id, startDate.getTime(), now.getTime())
            .then((data) => {
                let calendarPath = [];
                for (let p of data) {
                    let d = new Date(parseInt(p.timestamp));
                    calendarPath.push(d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString());
                }
                this.setState({
                    passerelle: event.value,
                    calendarPath: calendarPath
                });
            }).catch(error => {
                console.error(error);
            });
    }

    handleChangeDate(event) {
        this.setState({ date: event.value });
    }

    afficherCarte(b) {

        if (!window.google) {

            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBTwW1ZNLLockxUERSIlt4gU2OuU-6Cnj8&callback=initMap`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);

            s.addEventListener('load', e => {
                this.createMap(b);
                this.onScriptLoad(b)
                this.centerMap();
            })

        } else {

            if (this.theMap) {
                this.onScriptLoad(b);
                this.centerMap();
            }
            else {
                this.createMap(b);
                this.onScriptLoad(b)
                this.centerMap();
            }
        }
        this.setState({ isMap: true });
        this.setState({ isReturn: true });
        this.setState({ isForm: false });
        this.setState({ isDataTable: false });
    }

    loadTrajets() {
        const { formatMessage } = this.props.intl;
        this.messages.show({ severity: 'info', summary: formatMessage(messages.errorloading), detail: '', sticky: true });
        if (this.state.date && this.state.passerelle) {
            let from = new Date(this.state.date.getFullYear(), this.state.date.getMonth(), this.state.date.getDate());
            let to = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 1);
            this.trajetsService.getPathPacketsInRange(this.state.passerelle.id, from.getTime(), to.getTime())
                .then(data => {
                    this.messages.clear();
                    if (data && data.length === 0) {
                        this.messages.show({ severity: 'info', summary: '', detail: formatMessage(messages.errordata) + ` ${this.state.date.toLocaleDateString()}`, sticky: true });
                    } else if (auth.getUserInfo().surfaces) {
                        // for each run data.foreach add the surfaces to the first packet data[][0].surfaces = matches
                        data.map(run => {
                            let firstPacket = run[0];
                            let matchingSurfaces = auth.getUserInfo().surfaces.filter(surface => {
                                let firstPacket = run[0];
                                let lastPacket = run.slice(-1)[0];
                                const startCoor = { latitude: appUtil.lat(firstPacket.startCoor.latitude), longitude: appUtil.lng(firstPacket.startCoor.longitude) };
                                const endCoor = { latitude: appUtil.lat(lastPacket.endCoor.latitude), longitude: appUtil.lng(lastPacket.endCoor.longitude) };
                                const surfaceStartPosition = { latitude: appUtil.lat(surface.startPosition.latitude), longitude: appUtil.lng(surface.startPosition.longitude) };
                                const surfaceEndPosition = { latitude: appUtil.lat(surface.endPosition.latitude), longitude: appUtil.lng(surface.endPosition.longitude) };

                                let score = dsp.validateTrackOverArea(startCoor, endCoor, surfaceStartPosition, surfaceEndPosition, 25);
                                return score > 0
                            });
                            firstPacket.surfaces = matchingSurfaces;
                        });
                    }
                    this.setState({ trajets: data })
                }).catch((error => {
                    this.setState({ presstions: {} })
                    this.messages.show({ severity: 'warn', summary: 'Une erreur est survenue', detail: error.message, sticky: true });
                }));
        }
        else {
            this.messages.show({ severity: 'warn', summary: 'Attention', detail: formatMessage(messages.errorinput) });
        }
    }

    centerMap() {
        let i;
        this.bounds = new window.google.maps.LatLngBounds();

        for (i = 0; i < this.markers.length; i++) {
            this.bounds.extend(this.markers[i].getPosition());
        }
        this.theMap.setCenter(this.bounds.getCenter());
        setTimeout(function () { this.theMap.fitBounds(this.bounds); }.bind(this), 1);

    }



    afficherGraph(t) {
        const { formatMessage } = this.props.intl;
        if (t) {
            let x;
            let nbLabel = 0
            let labelsList = [];
            let dataList = [];

            let title = formatMessage(messages.track) + '-1'
            let datas = [];
            for (x = 0; x < t.length; x++) {
                datas.push(t[x].pressure)

                if (nbLabel <= x) {
                    labelsList.push(appUtil.formatTimeOnly(t[x].timestamp));
                    nbLabel++;
                }

            }
            dataList.push({
                label: title,
                data: datas,
                fill: false,
                backgroundColor: this.state.colorList[1],
                borderColor: this.state.colorList[1]

            })
            //}
            let presstionList = {
                labels: labelsList,
                datasets: dataList
            };

            this.setState({ presstions: presstionList });
            this.setState({ isGraph: true });
            this.setState({ isReturn: true });
            this.setState({ isForm: false });
            this.setState({ isDataTable: false });
        }
    }

    afficherVitesse(t) {

        if (t) {
            let x;
            let nbLabel = 0
            let labelsList = [];
            let dataList = [];

            let title = "Trajet-1"
            let datas = [];
            for (x = 0; x < t.length; x++) {
                datas.push(t[x].speed)

                if (nbLabel <= x) {
                    labelsList.push(appUtil.formatTimeOnly(t[x].timestamp));
                    nbLabel++;
                }

            }
            dataList.push({
                label: title,
                data: datas,
                fill: false,
                backgroundColor: this.state.colorList[1],
                borderColor: this.state.colorList[1]

            })
            //}
            let speedList = {
                labels: labelsList,
                datasets: dataList
            };

            this.setState({ speeds: speedList });
            this.setState({ isSpeedGraph: true });
            this.setState({ isGraph: false });
            this.setState({ isReturn: true });
            this.setState({ isForm: false });
            this.setState({ isDataTable: false });
        }

    }

    afficherPluviometrie(t) {

        const { formatMessage } = this.props.intl;
        this.setState({ processing: true });

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let mode = 1;
                // the index of the nozzle will always be one (1) and the first nozzle chart will be the corresponding nozzle of the selected gateway
                t[0].nozzle = 1; // HACK: This is a workaround of the limitation of the pluvio Object
                let userNozzleId = this.state.passerelle.userNozzleId;
                let nozzleCharts = []; // Should have only one element
                // Add the corresponding userNozzleId nozzle
                let matchingNozzles = auth.getUserInfo().nozzles.filter(n => n.userNozzleId.toString() === userNozzleId.toString());
                if (!matchingNozzles || matchingNozzles.length === 0) {
                    // TODO Translate error message
                    this.messages.show({ severity: 'warn', summary: 'ATTENTION', detail: <FormattedMessage id="warn.no.nozzle.found" defaultMessage="No sprinkler chart configured" />, sticky: true });
                    return reject(new Error(formatMessage(messages.errorNozzle)));
                }
                nozzleCharts.push(matchingNozzles[0]);
                // todo build the array of nozzles corresponding to the value of the gateway nozzle (2 param)
                let pathObj = new pluvio(t, nozzleCharts, mode);
                resolve(pathObj);
            }, 1);
        }).then(result => {
            this.setState(
                {
                    processing: false,
                    pathObj: result,
                    isSpeedGraph: false,
                    isRainfallGraph: true,
                    isGraph: false,
                    isReturn: true,
                    isForm: false,
                    isDataTable: false
                });
        }).catch(error => {
            this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            this.setState({ processing: false });
        });
    }

    returnAffichage() {
        this.setState({ isGraph: false });
        this.setState({ isSpeedGraph: false });
        this.setState({ isRainfallGraph: false });
        this.setState({ isMap: false });
        this.setState({ isForm: true });
        this.setState({ isDataTable: true })
        this.setState({ isReturn: false })

    }

    dateTemplate(date) {
        let d = date.year.toString() + date.month.toString() + date.day.toString();
        if (this.state && this.state.calendarPath && this.state.calendarPath.includes(d)) {
            return (
                <div style={{ backgroundColor: '#42a5f5', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', width: '2em', height: '2em', lineHeight: '2em', padding: 0 }}>{date.day}</div>
            );
        }
        else {
            return date.day;
        }
    }

    createMap(data) {
        if (data && typeof (data[0]) !== 'undefined') {
            this.theMap = new window.google.maps.Map(
                document.getElementById("mapId"), {
                options: {
                    center: { lat: appUtil.lat(data[0].latitude), lng: appUtil.lng(data[0].longitude) },
                    streetViewControl: false, maxZoom: 20
                }, mapTypeId: window.google.maps.MapTypeId.SATELLITE

            });
        }
    }

    onScriptLoad(data) {
        var iconGreen = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'green',
            fillOpacity: 1,
            strokeColor: 'green',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };

        var iconYellow = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'yellow',
            fillOpacity: 1,
            strokeColor: 'yellow',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };

        var iconFlag = config.iconBase + 'finish-flag.png';


        if (data && typeof (data[0]) !== 'undefined') {

            while (this.markers.length) {
                this.markers.pop().setMap(null);
            }
            this.bounds = new window.google.maps.LatLngBounds(null);

            var i;
            let firstMark;
            firstMark = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(data[0].startCoor.latitude),
                    lng: appUtil.lng(data[0].startCoor.longitude),
                },
                map: this.theMap,
                icon: iconGreen
            });
            this.markers.push(firstMark);

            let step = data.length <= config.maximum_google_map_markers ? 1 : (Math.trunc(data.length / config.maximum_google_map_markers));

            for (i = 0; i < data.length; i += step) {
                let makYellow = new window.google.maps.Marker({
                    position: {
                        lat: appUtil.lat(data[i].latitude),
                        lng: appUtil.lng(data[i].longitude),
                    },
                    map: this.theMap,
                    icon: iconYellow
                });
                this.markers.push(makYellow);
            }
            let makFlag = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(data[data.length - 1].endCoor.latitude),
                    lng: appUtil.lng(data[data.length - 1].endCoor.longitude),
                },
                map: this.theMap,
                icon: iconFlag
            });
            this.markers.push(makFlag);
            // if (firstMark) {
            //     this.theMap.setCenter(firstMark.getPosition());
            //}
        }
    }


    componentDidMount() {
        this.passerellesService.getAllPasserelles()
            .then((data) => {
                if (data) {
                    let p = [];
                    for (let i = 0; i < data.length; i++) {
                        p.push({ id: data[i].id, name: `${data[i].name} (SN: ${data[i].id})`, ...data[i] });
                    }
                    this.setState({
                        passerelles: p,
                    });
                } else {
                    this.messages.show({ severity: 'error', summary: 'ERREUR', detail: '', sticky: true });
                    this.setState({
                        passerelles: [],
                    });
                }
            }).catch((error) => {
                logger.error(error);
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: '', sticky: true });
            });

        if (/Mobi|Android/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true });
        }
    }

    nameBySerial(s) {
        var i;
        let name = '';


        for (i = 0; i < this.state.passerelles.length; i++) {
            if (this.state.passerelles[i].id === s) {
                name = this.state.passerelles[i].name

            }
        }
        return name;
    }

    actionTemplate(rowData, column) {
        return (
            <div>
                <span style={{ color: '#42A5F5' }}><FormattedMessage id="track.track" defaultMessage="Track" />-{column.rowIndex + 1}</span>
                <span style={{ color: '#66BB6A' }}>{this.nameBySerial(rowData[0].serialNumber)}</span>
                <span style={{ color: '#42A5F5' }}> <FormattedMessage id="track.start" defaultMessage="Start" /> :</span>
                {appUtil.formatTimeStamp2(rowData[0].pathStartTimestamp ? rowData[0].pathStartTimestamp : rowData[0].timestamp)}
                <span style={{ color: '#1dcbb3' }}> <FormattedMessage id="track.end" defaultMessage="End" /> :</span>
                {appUtil.formatTimeStamp2(rowData[0].pathEndTimestamp ? rowData[0].pathEndTimestamp : rowData[rowData.length - 1].timestamp)}
                <span style={{ color: '#42A5F5' }}> <FormattedMessage id="track.duration" defaultMessage="Duration" /> :</span>
                {rowData[0].pathStartTimestamp && rowData[0].pathEndTimestamp ?
                    ((parseInt(rowData[0].pathEndTimestamp) - parseInt(rowData[0].pathStartTimestamp)) / 3600000).toFixed(1) + ' h'
                    : appUtil.Duration(rowData[0].timestamp, rowData[rowData.length - 1].timestamp)
                }
                <span style={{ color: '#66BB6A' }}> <FormattedMessage id="track.run" defaultMessage="Run" /> :</span>
                {rowData[0].pathDistance ? parseInt(rowData[0].pathDistance).toFixed(1) : rowData[0].endPointDistance} m
            </div>);
    }

    buttonTemplate(rowData, column) {
        return (
            <div className="button-demo">
                <ButtonI18n i18nId="track.rainfall" defaultMessage="Rainfall" onClick={() => this.afficherPluviometrie(rowData)} className="p-button-rounded" />
                <ButtonI18n i18nId="track.map" defaultMessage="Map" onClick={() => this.afficherCarte(rowData)} className="p-button-rounded  p-button-success" />
            </div>
        );
    }

    surfaceTemplate(rowData, column) {
        if (rowData && rowData[0] && rowData[0].surfaces && rowData[0].surfaces.length > 0) {
            return rowData[0].surfaces.map(surface =>
                <Link
                    to={{
                        pathname: "/surface/editSurface/" + surface.id,
                        state: { selectedRun: surface }
                    }}
                > {surface.description}</Link >);
        } else {
            return (
                <div className="button-demo">
                    <Link to={{
                        pathname: '/surface/addSurface',
                        state: { selectedRun: rowData[0] }
                    }}>
                        <ButtonI18n i18nId="track.surface.define" defaultMessage="Define as new surface" className="p-button-rounded  p-button-danger" />
                    </Link>
                </div>
            );
        }
    }

    render() {
        const { formatMessage } = this.props.intl;

        const frCa = {
            firstDayOfWeek: 1,
            closeText: "Fermer",
            prevText: "Précédent",
            nextText: "Suivant",
            monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"],
            dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
            dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            dayNamesMin: ["D", "L", "Ma", "M", "J", "V", "S"],
            weekHeader: "Semaine",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: "",
            timeOnlyTitle: "Choisir l\"heure",
            timeText: "Heure",
            hourText: "Heures",
            minuteText: "Minutes",
            secondText: "Secondes",
            currentText: "Maintenant",
            ampm: false,
            month: "Mois",
            week: "Semaine",
            day: "Jour",
            allDayText: "Toute la journée"
        };
        let stylebutton = {
            marginLeft: '10px',
        };
        const options = {
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']];
                    },
                    // afterLabel: function(tooltipItem, data) {   
                    //  var dataset = data['datasets'][0];
                    // var percent = dataset['data'][tooltipItem['index']]                
                    //return   '(' + percent + '%)';
                    // }
                }
            }
        };

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1><FormattedMessage id="menu.tracks" defaultMessage="Tracks" /></h1>
                        <LoadingSpinner rendered={this.state.processing} i18nId="spinner.loading" />
                        <Messages ref={(el) => this.messages = el} />
                        <div style={this.state.isForm ? {} : { display: 'none' }} className="p-col-12 p-md-6">
                            <h3><FormattedMessage id="track.select"
                                defaultMessage="Select a gateway and a date" /></h3>
                            <div className="p-grid">
                                <div className="p-col"> <Dropdown value={this.state.passerelle} options={this.state.passerelles} onChange={this.handleChangeGateway} placeholder={formatMessage(messages.selectgateway)} optionLabel="name" /></div>
                                <div className="p-col">
                                    {(this.props.intl.locale !== 'en') &&
                                        <Calendar touchUI={this.state.isMobile} readOnlyInput={true} placeholder="Date" value={this.state.date} onChange={this.handleChangeDate} locale={frCa} dateFormat="yy-mm-dd" dateTemplate={this.dateTemplate}></Calendar>}

                                    {(this.props.intl.locale === 'en') &&
                                        <Calendar touchUI={this.state.isMobile} readOnlyInput={true} placeholder="Date" value={this.state.date} onChange={this.handleChangeDate} dateFormat="yy-mm-dd" dateTemplate={this.dateTemplate}></Calendar>}
                                </div>
                                <div className="p-col"><ButtonI18n i18nId="track.refresh" defaultMessage="Refresh" className="button-auto" style={stylebutton} onClick={this.loadTrajets} label="Afficher" /></div>
                            </div>
                        </div>

                        <DataTable style={this.state.isDataTable ? {} : { display: 'none' }} header={<FormattedMessage id="track.list"
                            defaultMessage="Liste of tracks" />} value={this.state.trajets} selection={this.state.selectedTrajet}
                            onSelectionChange={event => this.setState({ selectedTrajet: event.value })}
                            responsive={true} >
                            <Column body={this.actionTemplate} header="Description" />
                            <Column body={this.buttonTemplate.bind(this)} header={<FormattedMessage id="track.info"
                                defaultMessage="Information" />} />
                            <Column body={this.surfaceTemplate.bind(this)} header={<FormattedMessage id="track.surface"
                                defaultMessage="Surface" />} />
                        </DataTable>

                        <ButtonI18n i18nId="state.back" defaultMessage="Back" style={this.state.isReturn ? stylebutton : { display: 'none' }} className="button-auto" onClick={this.returnAffichage} />

                        <div style={this.state.isGraph ? {} : { display: 'none' }} className="p-col-12 p-lg-12"> <h3><FormattedMessage id="state.pressure" defaultMessage="" /></h3>
                            <Chart type="line" data={this.state.presstions} options={options} />
                        </div>

                        <div style={this.state.isSpeedGraph ? {} : { display: 'none' }} className="p-col-12 p-lg-12"> <h3><FormattedMessage id="state.speed" defaultMessage="" /></h3>
                            <Chart type="line" data={this.state.speeds} options={options} />
                        </div>

                        <div style={this.state.isRainfallGraph ? {} : { display: 'none' }} className="p-col-12 p-lg-12"> <h3><FormattedMessage id="state.rainfall" defaultMessage="" /></h3>
                            {this.state.isRainfallGraph && <PluvioAllInOneChart pathObj={this.state.pathObj} />}
                            {this.state.isRainfallGraph && <PluvioAllInOneChartTime pathObj={this.state.pathObj} />}
                        </div>


                        <div style={this.state.isMap ? {} : { display: 'none' }} className="p-col-12 p-lg-12 "> <h3><FormattedMessage id="track.selectmap" defaultMessage="" /></h3>
                            <div className="card p-col">
                                <div id="mapId" style={{ width: '100%', height: '500px' }} > </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default injectIntl(Trajet);
