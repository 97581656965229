import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// import { NavLink } from 'react-router-dom'
// import { Button } from 'primereact/button';


export class EtatSys extends Component {

    render() {
        let stateTitleValues = {
            name: this.props.dataEtatsValue.name,
            sn: this.props.dataEtatsValue.id,
            firmware: this.props.dataEtatsValue.firmware ? this.props.dataEtatsValue.firmware.toString() : "0000"
        };

        let remainingTime = this.props.dataEtatsValue.speed !== 0 ? ((this.props.dataEtatsValue.distance * 60) / this.props.dataEtatsValue.speed) : -1;
        let arrivalTime = remainingTime >= 0 ? new Date(Date.now() + (remainingTime * 60000)).toLocaleTimeString() : 'NA';

        return (
            <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="card  p-col">
                        <h1>
                            <FormattedMessage id="state.ofSN" defaultMessage="State of {name} (SN:{sn}, V{firmware})" values={stateTitleValues} />
                        </h1>

                        <div className="p-grid">
                            <div className="p-col-4 p-sm-6" style={{ backgroundColor: '#e91224', display: this.props.dataEtatsValue.alert ? 'block' : 'none' }}>
                                <FormattedMessage id="state.alert" defaultMessage="Alert:" />
                            </div>
                            <div className="p-col-8 p-sm-6" style={{ backgroundColor: '#e91224', display: this.props.dataEtatsValue.alert ? 'block' : 'none' }}>
                                {this.props.dataEtatsValue.alert}
                            </div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.date" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6">{this.props.dataEtatsValue.date}</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.hour" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6">{this.props.dataEtatsValue.heure}</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.state" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6">{this.props.dataEtatsValue.etat}</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.pressure" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6">{(this.props.dataEtatsValue.pression * 0.0689476).toFixed(1)} <FormattedMessage id="unit.bar" defaultMessage="" /> ({this.props.dataEtatsValue.pression} <FormattedMessage id="unit.psi" defaultMessage="" />)</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.speed" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6">{this.props.dataEtatsValue.speed} <FormattedMessage id="unit.m.per.h" defaultMessage="" /> ({((this.props.dataEtatsValue.speed * 39.37008) / 60).toFixed(1)} <FormattedMessage id="unit.in.per.min" defaultMessage="" />)</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.distance" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6"> {this.props.dataEtatsValue.distance} <FormattedMessage id="unit.m" defaultMessage="" /> ({(this.props.dataEtatsValue.distance * 3.28084).toFixed(0)} <FormattedMessage id="unit.ft" defaultMessage="" />)</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.remainingTime" defaultMessage="Temps restant" />
                            </div>
                            <div className="p-col-8 p-sm-6"> {remainingTime >= 0 ? remainingTime.toFixed(0) + ' min' : <FormattedMessage id="state.resolver.NA" defaultMessage="NA" />}</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.arrivalTime" defaultMessage="Estimated Arrival Time" />
                            </div>
                            <div className="p-col-8 p-sm-6"> {arrivalTime !== 'NA' ? arrivalTime : <FormattedMessage id="state.resolver.NA" defaultMessage="NA" />}</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.lastMovement" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6"> {this.props.dataEtatsValue.mouvement} s</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.battLevel" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6"> {this.props.dataEtatsValue.batterie.toFixed(1)} V</div>

                            <div className="p-col-4 p-sm-6">
                                <FormattedMessage id="state.temperature" defaultMessage="" />
                            </div>
                            <div className="p-col-8 p-sm-6"> {this.props.dataEtatsValue.temp} °C ({((this.props.dataEtatsValue.temp * 1.8) + 32).toFixed(0)} F)</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}