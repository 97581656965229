import React, { Component } from 'react';

class SimpleForm extends Component {

    constructor(props) {
        super(props);
        this.state = { firstname: '', lastname: '' };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        alert('Le nom a été soumis : ' + this.state.firstname + ' ' + this.state.lastname);
        this.setState({ firstname: '', lastname: '' });
        event.preventDefault();
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <form onSubmit={this.handleSubmit}>
                            <label>
                                Firstname :
                            </label>
                            <input type="text" value={this.state.firstname} onChange={event => this.setState({ firstname: event.target.value })} />
                            <br />
                            <label>
                                Lastname :
                            </label>
                            <input type="text" value={this.state.lastname} onChange={event => this.setState({ lastname: event.target.value })} />
                            <br />
                            <input type="submit" value="Envoyer" />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SimpleForm;