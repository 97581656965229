import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';

class AdminService {

    getAcLastPackets() {
        return BaseServiceClient.get(config.beAcLastPackets);
    }

    getIrLastPackets() {
        return BaseServiceClient.get(config.beIrLastPackets);
    }
}

export default AdminService;