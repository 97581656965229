const appUtil = {

  lat(t) {
    if (!t) {
      return
    }
    let gpsLat = t.toString()
    let latSign = gpsLat.slice(gpsLat.length - 1) === 'N' ? 1 : -1
    let latDeg = gpsLat.slice(0, 2)
    let latDecimalDeg = Number(gpsLat.slice(2, gpsLat.length - 1)) / 60
    let lat = (Number(latDeg) + latDecimalDeg) * latSign
    return lat
  },

  lng(g) {
    if (!g) {
      return
    }

    let gpsLng = g.toString()
    let lngSign = gpsLng.slice(gpsLng.length - 1) === 'E' ? 1 : -1;
    let lngDeg = gpsLng.slice(0, 3);
    let lngDecimalDeg = Number(gpsLng.slice(3, gpsLng.length - 1)) / 60;
    let lng = (Number(lngDeg) + lngDecimalDeg) * lngSign;

    return lng
  },

  formatDate(d) {
    if (d) {
      return this.fixDigit(d.getDate()) + this.fixDigit(d.getMonth() + 1) + d.getFullYear().toString();
    }
  },

  fixDigit(val) {
    return val.toString().length === 1 ? "0" + val.toString() : val.toString();
  },

  iconC(i, m) {
    let icon;

    let iconGreen = {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: 'green',
      fillOpacity: 1,
      strokeColor: 'green',
      strokeOpacity: 1,
      strokeWeight: 1,
      scale: 7
    };

    let iconYellow = {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: 'yellow',
      fillOpacity: 1,
      strokeColor: 'yellow',
      strokeOpacity: 1,
      strokeWeight: 1,
      scale: 7
    };
    if (i === 0) {
      icon = iconGreen;
    } else if (i === m) {
      icon = iconYellow;
    } else {
      icon = iconYellow;
    }
    return icon;
  },
  formatTimeStamp(t) {
    let date = new Date(0);
    date.setUTCSeconds(t / 1000);
    let title = date.toLocaleDateString("fr-CA") + ' ' + date.toLocaleTimeString("fr-CA");
    return title
  },
  formatTimeStamp2(t) {
    let date = new Date(0);
    date.setUTCSeconds(t / 1000);
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    let title = date.toLocaleDateString("fr-CA", options);
    return title
  },
  formatTimeOnly(t) {
    let date = new Date(0);
    date.setUTCSeconds(t / 1000);
    var options = { hour: '2-digit', minute: '2-digit' };
    let title = date.toLocaleTimeString("fr-CA", options);
    return title
  },
  Duration(s, e) {
    let dateStart = new Date(0);
    let dateEnd = new Date(0);

    dateStart.setUTCSeconds(s / 1000);
    dateEnd.setUTCSeconds(e / 1000);


    var end = new Date(dateEnd);
    var start = new Date(dateStart);

    var seconds = Math.floor((end - (start)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    //TODO faire la gestion des jours
    return  hours + 'h ' + minutes +'min';
  },

};
export default appUtil;