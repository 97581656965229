import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import ButtonI18n from '../i18n/ButtonI18n';
import { Messages } from 'primereact/messages';
import BaseServiceClient from '../../service/BaseServiceClient';
import LoadingSpinner from '../LoadingSpinner';

class PrimeForm extends Component {

    constructor(props) {
        super(props);
        this.state = { firstname: '', lastname: '', statusCode: '500', delay: '2000' };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitError = this.handleSubmitError.bind(this);
    }

    handleSubmit(event) {
        alert('Submitting : ' + this.state.firstname + ' ' + this.state.lastname);
        // this.messages.show({ severity: 'info', summary: <FormattedMessage id="spinner.saving" defaultMessage="LOADING ..." />, detail: '', sticky: true, closable: false });
        this.setState({ saving: true });
        BaseServiceClient.get('http://localhost:3001/sim/sleep/'+this.state.delay)
            .then(result => {
                this.messages.clear();
                this.messages.show({ severity: 'info', summary: 'Success', detail: this.state.firstname + ' ' + this.state.lastname, sticky: true });
                this.setState({ saving: false, firstname: '', lastname: '' });
            }).catch(error => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
                this.setState({ saving: false });
            });

        event.preventDefault();
    }

    handleSubmitError(event) {
        alert('Submitting : ' + this.state.firstname + ' ' + this.state.lastname);
        //this.messages.show({ severity: 'info', summary: <FormattedMessage id="spinner.saving" defaultMessage="LOADING ..." />, detail: '', sticky: true, closable: false });
        this.setState({ saving: true });
        BaseServiceClient.get('http://localhost:3001/sim/error/'+this.state.statusCode)
            .then(result => {
                this.messages.clear();
                this.messages.show({ severity: 'info', summary: `Save succeed (${this.state.firstname} ${this.state.lastname})`, detail: result, sticky: true });
                this.setState({ saving: false, firstname: '', lastname: '' });
            }).catch(error => {
                this.messages.clear();
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
                this.setState({ saving: false });
            });

        event.preventDefault();
    }

    render() {
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <LoadingSpinner rendered={this.state.saving} i18nId="spinner.saving" />
                        <Messages ref={(el) => this.messages = el} />
                        <form onSubmit={this.handleSubmit}>
                            <p className="p-float-label">
                                <InputText id="firstname" value={this.state.firstname} onChange={event => this.setState({ firstname: event.target.value })} />
                                <label htmlFor="firstname">Firstname</label>
                            </p>
                            <p className="p-float-label">
                                <InputText id="lastname" value={this.state.lastname} onChange={event => this.setState({ lastname: event.target.value })} />
                                <label htmlFor="lastname">Lastname</label>
                            </p>
                            <p className="p-float-label">
                                <InputText id="delay" value={this.state.delay} onChange={event => this.setState({ delay: event.target.value })} />
                                <label htmlFor="delay">Timeout delay</label>
                            </p>
                            <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={this.handleSubmit} />
                            <p className="p-float-label">
                                <InputText id="statusCode" value={this.state.statusCode} onChange={event => this.setState({ statusCode: event.target.value })} />
                                <label htmlFor="statusCode">Error Code Simulation</label>
                            </p>
                            <ButtonI18n i18nId="commons.save" defaultMessage="Save Error" icon="pi pi-times" className="p-button-danger" onClick={this.handleSubmitError} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrimeForm;