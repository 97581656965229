import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Messages } from 'primereact/messages';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner';
import { dsp } from '../src/dsp';
import appUtil from '../../utils/appUtil';
import { TrajetsService } from '../../service/TrajetsService';
import auth from '../../utils/auth';
import pluvio from '../src/pluvio';
import { Button } from 'primereact/button';
import * as XLSX from 'xlsx';

class SurfaceRuns extends Component {

    constructor(props) {
        super();
        this.state = {
            loading: true,
            runs: [],
            surface: props.location.state.surface
        };
        this.trajetsService = new TrajetsService();
        this.export = this.export.bind(this);
    }

    componentDidMount() {
        // load all start path and validate with surface
        const surface = this.state.surface;
        const sn = this.state.surface.id.split('-')[0];
        const from = Date.parse('2020-01-01');
        const to = Date.parse('2020-12-31');
        this.trajetsService.getPathPacketsInRange(sn, from, to)
            .then(allRuns => {
                let matchingRuns = allRuns.filter(run => {
                    let firstPacket = run[0];
                    let lastPacket = run.slice(-1)[0];
                    const startCoor = { latitude: appUtil.lat(firstPacket.startCoor.latitude), longitude: appUtil.lng(firstPacket.startCoor.longitude) };
                    const endCoor = { latitude: appUtil.lat(lastPacket.endCoor.latitude), longitude: appUtil.lng(lastPacket.endCoor.longitude) };
                    const surfaceStartPosition = { latitude: appUtil.lat(surface.startPosition.latitude), longitude: appUtil.lng(surface.startPosition.longitude) };
                    const surfaceEndPosition = { latitude: appUtil.lat(surface.endPosition.latitude), longitude: appUtil.lng(surface.endPosition.longitude) };

                    let score = dsp.validateTrackOverArea(startCoor, endCoor, surfaceStartPosition, surfaceEndPosition, 25);
                    return score > 0
                });
                let newRuns = matchingRuns.map(run => {
                    let mode = 1;
                    // the index of the nozzle will always be one (1) and the first nozzle chart will be the corresponding nozzle of the selected gateway
                    run[0].nozzle = 1; // HACK: This is a workaround of the limitation of the pluvio Object
                    const currentGateway = auth.getUserInfo().gateways.filter(g => g.id === sn);
                    let userNozzleId = currentGateway && currentGateway.length > 0 ? currentGateway[0].userNozzleId : 1; //this.state.passerelle.userNozzleId;
                    let nozzleCharts = []; // Should have only one element
                    // Add the corresponding userNozzleId nozzle
                    let matchingNozzles = auth.getUserInfo().nozzles.filter(n => n.userNozzleId.toString() === userNozzleId.toString());
                    nozzleCharts.push(matchingNozzles[0]);
                    // todo build the array of nozzles corresponding to the value of the gateway nozzle (2 param)
                    let pathObj = new pluvio(run, nozzleCharts, mode);
                    let dose = pathObj.computeDose();
                    run[0].avgDose = dose.avgDose;
                    run[0].vol = dose.vol;
                    return run;
                });
                this.setState({ runs: newRuns, loading: false });
                console.log(newRuns);
            }).catch(e => {
                this.setState({ loading: false });
                this.messages.show({ severity: 'error', summary: 'Une erreur est survenue', detail: e.message, sticky: true });
            })
    }

    export() {
        let jsonSheet = [];
        const runs = this.state.runs;
        for (let i = 0; i < runs.length; i++) {
            const rowData = runs[i];
            const startTemplate = appUtil.formatTimeStamp2(rowData[0].timestamp);
            const endTemplate = appUtil.formatTimeStamp2(rowData[rowData.length - 1].timestamp);
            const durationTemplate = appUtil.Duration(rowData[0].timestamp, rowData[rowData.length - 1].timestamp);
            const distanceTemplate = rowData[0].endPointDistance;
            const pluvioTemplate = `${rowData[0].avgDose}`;
            const volTemplate = `${rowData[0].vol}`;
            jsonSheet.push({
                "Début": startTemplate,
                "Fin": endTemplate,
                "Durée": durationTemplate,
                "Distance": distanceTemplate,
                "Pluviométrie (mm)": pluvioTemplate,
                "Volume d\'eau (m³)": volTemplate
            });
        }
        const pluvioMean = runs.reduce((acc, cur) => acc + cur[0].avgDose, 0) / runs.length;
        const volumeTotal = runs.reduce((acc, cur) => acc + cur[0].vol, 0);
        jsonSheet.push({
            "Distance": 'Totaux:',
            "Pluviométrie (mm)": pluvioMean,
            "Volume d\'eau (m³)": volumeTotal
        });

        const ws = XLSX.utils.json_to_sheet(jsonSheet);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        XLSX.writeFile(wb, 'out.xlsx');
    }

    render() {

        const title = <FormattedMessage id="surfaces.runs" defaultMessage="Surface Runs">
            {formattedMessage => formattedMessage + ' ' + this.state.surface.description}
        </FormattedMessage>;
        const header = <div>
            {title}
            <div style={{ textAlign: 'right' }}>
                <Button type="button" icon="pi pi-external-link" label="Export" onClick={this.export}></Button>
            </div>
        </div>
        const footer = <div>
            <p>Pluviométrie moyenne: {this.state.runs.reduce((acc, cur) => acc + cur[0].avgDose, 0) / this.state.runs.length} mm</p>
            <p>Volume d'eau Total: {this.state.runs.reduce((acc, cur) => acc + cur[0].vol, 0)} m³</p>
        </div>;
        const startTemplate = (rowData) => {
            return appUtil.formatTimeStamp2(rowData[0].timestamp);
        };
        const endTemplate = (rowData) => {
            return appUtil.formatTimeStamp2(rowData[rowData.length - 1].timestamp);
        };
        const durationTemplate = (rowData) => {
            return appUtil.Duration(rowData[0].timestamp, rowData[rowData.length - 1].timestamp);
        };
        const distanceTemplate = (rowData) => {
            return rowData[0].endPointDistance;
        };
        const pluvioTemplate = (rowData) => {
            return `${rowData[0].avgDose} mm`;
        };
        const volTemplate = (rowData) => {
            return `${rowData[0].vol} m³`;
        }

        return (
            <div>
                <LoadingSpinner rendered={this.state.loading} i18nId="spinner.loading" />
                <Messages ref={(el) => this.messages = el} />
                <DataTable value={this.state.runs} responsive={true} header={header} footer={footer}>
                    <Column header="Début" body={startTemplate} />
                    <Column header="Fin" body={endTemplate} />
                    <Column header="Durée" body={durationTemplate} />
                    <Column header="Distance" body={distanceTemplate} />
                    <Column header="Pluviométrie" body={pluvioTemplate} />
                    <Column header="Volume d'eau" body={volTemplate} />
                </DataTable>
            </div>
        );
    }
}

export default SurfaceRuns;