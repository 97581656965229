import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AdminService from '../service/AdminService';
import { Messages } from 'primereact/messages';
import LoadingSpinner from './LoadingSpinner';

class LastPacketList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            acPackets: [],
            irPackets: [],
            processing: true
        };
        this.adminService = new AdminService();
    }

    sortTimestampAsc(a, b) {
        if (!a.ts) return -1;
        if (!b.ts) return 1;
        return a.ts - b.ts;
    }

    sortTimestampDesc(a, b) {
        if (!a.ts) return 1;
        if (!b.ts) return -1;
        return b.ts - a.ts;
    }

    componentDidMount() {
        Promise.all([this.adminService.getAcLastPackets(), this.adminService.getIrLastPackets()])
            .then((results) => {
                this.setState({ acPackets: results[0].sort(this.sortTimestampAsc), irPackets: results[1].sort(this.sortTimestampAsc), processing: false });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ processing: false });
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    timestampTemplate(rowData, column) {
        if (rowData['ts']) {
            let date = new Date(parseInt(rowData['ts']));
            let d = date.getDate().toString().padStart(2, '0');
            let m = (date.getMonth() + 1).toString().padStart(2, '0');
            let y = date.getFullYear();
            let h = date.getHours().toString().padStart(2, '0');
            let min = date.getMinutes().toString().padStart(2, '0');
            let day = [y, m, d].join('-'); //date.toLocaleDateString();
            let time = h + ':' + min; //date.toLocaleTimeString();
            return <div style={{ fontSize: 'large', fontWeight: 'bold' }}><span style={{ color: 'blue' }}>{day}</span>&#160;&#160;<span style={{ color: 'green' }}>{time}</span></div>;
        } else {
            return 'No packet found';
        }
    }

    render() {
        return (
            <div>
                <Messages ref={(el) => this.messages = el} />
                <LoadingSpinner rendered={this.state.processing} i18nId="spinner.loading" />
                <DataTable value={this.state.acPackets} responsive={true} header="Acériculture" emptyMessage="No packets">
                    <Column field="sn" header="Serial Number" sortable />
                    <Column field="name" header="Name" sortable />
                    <Column field="ts" header="Timestamp" body={this.timestampTemplate} sortable />
                    <Column field="data" header="data" />
                </DataTable>
                <DataTable value={this.state.irPackets} responsive={true} header="Irrigation" emptyMessage="No packets">
                    <Column field="sn" header="Serial Number" sortable />
                    <Column field="name" header="Name" sortable />
                    <Column field="ts" header="Timestamp" body={this.timestampTemplate} sortable />
                    <Column field="data" header="data" />
                </DataTable>
            </div>
        );
    }
}

export default LastPacketList;