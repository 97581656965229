import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';

class GatewayService {

    list() {
        return BaseServiceClient.get(config.beIrListGateways);
    }

    get(phoneNumber) {
        return BaseServiceClient.get(config.beGetUserURL(phoneNumber));
    }

    save(gatewayConfig) {
        return BaseServiceClient.post(config.beAdminGateway, gatewayConfig);
    }
}

export default GatewayService;