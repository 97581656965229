import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';

// TODO Use config
const key = 'pk_test_NIs2AiffVyslUBjWMjTo9UHU002iS8rONM';

class CheckoutWrapper extends Component {
  render() {
    return (
      <StripeProvider apiKey={key}>
        <div className="example">
          <h1>Billing</h1>
          <Elements>
            <CheckoutForm />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}

export default CheckoutWrapper;