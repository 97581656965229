import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';
import auth from '../utils/auth';

class UserService {

    list() {
        return BaseServiceClient.get(config.beListUsersURL);
    }

    get(username) {
        return BaseServiceClient.get(config.beGetUserURL(username));
    }

    getCurrentUser() {
        return this.get(auth.getUserInfo().username);
    }

    add(user) {
        const body = { u: user };
        return BaseServiceClient.post(config.beAddUserURL, body);
    }

    save(user) {
        return BaseServiceClient.post(config.beUserURL, { u: user })
            .then(user => {
                auth.setUserInfo(user);
                return user;
            });
    }

    delete(user) {
        return BaseServiceClient.post(config.beUserURL, { u: user });
    }

    deleteUserNozzle(userNozzleId) {
        let user = auth.getUserInfo();
        let l = user.nozzles ? user.nozzles.length : 0;
        for (let i = 0; i < l; i++) {
            if (user.nozzles[i].userNozzleId === userNozzleId) {
                user.nozzles.splice(i, 1);
                return this.save(user);
            }
        }
    }

    deleteUserSurface(surfaceId) {
        console.log(surfaceId);
        let user = auth.getUserInfo();
        console.log(user);
        let l = user.surfaces ? user.surfaces.length : 0;
        console.log("nb surface: " + l);
        for (let i = 0; i < l; i++) {
            if (user.surfaces[i].id === surfaceId) {
                user.surfaces.splice(i, 1);
                return this.save(user);
            }
        }
    }
}

export default UserService;