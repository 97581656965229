import React, { Component } from 'react';
import NozzleService from '../service/NozzleService';
import logger from '../config/logger';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';

class Fabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fabList: []
        };
        this.busesService = new NozzleService();
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to="/" >
                <Button type="button" icon="pi pi-search" className="p-button-success" style={{ marginRight: '.5em' }}></Button>
            </Link>
        </div>;
    }

    componentDidMount() {

        this.busesService.getNozzleFabList()
            .then(data => this.setState({ fabList: data }))
            .catch((error) => {
                logger.error(error);
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: '', sticky: true });
            });
    }

    render() {
        return (
            <div>
                <Messages ref={(el) => this.messages = el} />
                <DataTable value={this.state.fabList}>
                    <Column field="id" header="id" />
                    <Column field="name" header="Frabricant" />
                    <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                </DataTable>
                <label>Add Fab</label>
                <InputText value={this.state.newFab} />
            </div>
        );
    }
}

export default Fabs;