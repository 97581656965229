import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'fullcalendar/dist/fullcalendar.css';
import './layout/layout.css';
import './App.css';
import App from './App';
import AuthPage from './containers/AuthPage';

/** Layouts **/
import LoginLayoutRoute from "./LoginLayout";
import PrivateRoute from './containers/PrivateRoute'
//import DashboardLayoutRoute from "./DashboardLayout";  
import { IntlProvider } from 'react-intl';
import messages_fr from './translations/fr.json';
import messages_en from './translations/en.json';
const nvLanguage = navigator.language.split(/[-_]/)[0];
const messages = {
  'fr': messages_fr,
  'en': messages_en
};

class AppWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      language: nvLanguage,
    };
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(s) {
    if (s === 'fr')
      this.setState({ language: 'fr' })
    else
      this.setState({ language: 'en' })
  }

  render() {

    return (
      <Router>
        <Switch>
          <LoginLayoutRoute path="/auth/Login" component={AuthPage} />
          <IntlProvider locale={this.state.language} key={this.state.language} messages={messages[this.state.language]}>
            <PrivateRoute path="/" component={App} onChangeLanguage={this.changeLanguage} language={this.state.language} />
          </IntlProvider>
        </Switch>
      </Router>
    );
  }
}

export default AppWrapper;
