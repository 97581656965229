import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserService from '../service/UserService';
import { Link } from 'react-router-dom';
import { Messages } from 'primereact/messages';

class UserList extends Component {

    constructor() {
        super();
        this.state = {
            users: []
        };
        this.userService = new UserService();
        this.usernameTemplate = this.usernameTemplate.bind(this);
    }

    componentDidMount() {
        this.userService.list()
            .then((result) => {
                this.setState({ users: result });
            })
            .catch((error) => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    usernameTemplate(rowData, column) {
        return <Link
            to={{
                pathname: "/user",
                username: rowData['username']
            }}
        > {rowData['username']}</Link >;
    }

    render() {
        return (
            <div>
                <Messages ref={(el) => this.messages = el} />
                <DataTable value={this.state.users} responsive={true} header="User List" emptyMessage="No users">
                    <Column field="username" header="Username" body={this.usernameTemplate} />
                    <Column field="firstname" header="Firstname" />
                    <Column field="lastname" header="Lastname" />
                </DataTable>
            </div>
        );
    }
}

export default UserList;