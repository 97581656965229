import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

export class AppFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            version: this.props.version
        };
    }
    render() {
        return  (
            <div className="layout-footer">
                <span className="footer-text-left">
                    <strong>
                        Copyright &#169; 2016-{new Date().getFullYear()}  <a target="_blank" rel="noopener noreferrer" href="https://trilogiktech.ca/">Trilogik Technologies inc.</a> <FormattedMessage id="footer.rights" defaultMessage="All Rights Reserved" /> (Version {this.state.version})
                    </strong> 
                </span>
            </div>
        );
    }
}