import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';
import auth from '../utils/auth';

export class PasserellesService {

    getGatewaysForUser(username) {
        return BaseServiceClient.get(config.beGatewaysURL + '?u=' + username);
    }

    getAllPasserelles() {
        return BaseServiceClient.get(config.beGatewaysURL);
    }

    getPasserelle(id) {
        return BaseServiceClient.get(config.beGatewayURL(id));
    }

    save(passerelles, username = auth.getUserInfo().username) {
        const body = { u: username, gateways: passerelles }
        return BaseServiceClient.post(config.beGatewaysURL, body);
    }
}