import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import ButtonI18n from './i18n/ButtonI18n';
import UserService from '../service/UserService';
import logger from '../config/logger';
import { Messages } from 'primereact/messages';
import { InputMask } from 'primereact/inputmask';

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: ''
        };
        this.save = this.save.bind(this);
        this.userService = new UserService();
    }

    save() {
        const user = {
            username: this.state.username,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password
        }
        this.userService.add(user)
            .then((data) => {
                logger.log(data);
                if (data) {
                    this.setState({
                        username: '',
                        password: '',
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: ''
                    });
                    this.messages.show({ severity: 'info', summary: 'Success', detail: user.username + ' user created', sticky: true });
                } else {
                    this.messages.show({ severity: 'error', summary: 'ERREUR', detail: 'NO DATA', sticky: true });
                }
            }).catch((error) => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Messages ref={(el) => this.messages = el} />
                        <h1>New User</h1>
                        <p className="p-float-label">
                            <InputText id="username" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                            <label htmlFor="username">Username</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                            <label htmlFor="password">Password</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="firstname" value={this.state.firstname} onChange={(e) => this.setState({ firstname: e.target.value })} />
                            <label htmlFor="firstname">First Name</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="lastname" value={this.state.lastname} onChange={(e) => this.setState({ lastname: e.target.value })} />
                            <label htmlFor="lastname">Last Name</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                            <label htmlFor="email">Email</label>
                        </p>
                        <p className="p-float-label">
                            <InputMask id="phone" mask="999-999-9999" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                            <label htmlFor="phone">Phone Number</label>
                        </p>
                        <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={this.save} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AddUser;