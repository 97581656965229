import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import CheckoutService from '../service/CheckoutService';
import ButtonI18n from './i18n/ButtonI18n';

const cardElementStyle = {
    base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
            color: "#aab7c4"
        }
    },
    invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
    }
};

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = { complete: false, error: false };
        this.submit = this.submit.bind(this);
        this.checkoutService = new CheckoutService();
    }

    async submit(ev) {
        let { token } = await this.props.stripe.createToken({ name: "Name" });
        this.checkoutService.applyPayment(token.id)
            .then((response) => {
                if (response.status === 'succeeded') {
                    this.setState({ complete: true });
                } else {
                    this.setState({ complete: false });
                };
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    }

    render() {
        if (this.state.complete) {
            return <h1>Purchase Complete</h1>;
        }
        if (this.state.error) {
            return <h1>Error occured</h1>;
        }
        return (
            <div className="checkout">
                <p>Would you like to complete the purchase?</p>
                <CardElement hidePostalCode={true} style={cardElementStyle} />
                <ButtonI18n i18nId="checkout" defaultMessage="Proceed with payment" icon="pi pi-check" onClick={this.submit} />
            </div>
        );
    }
}

export default injectStripe(CheckoutForm);