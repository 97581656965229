import React, { Component } from 'react';
import Buse from './Buse';
import { Messages } from 'primereact/messages';
import UserService from '../service/UserService';
import ButtonI18n from './i18n/ButtonI18n';
import { Link } from 'react-router-dom';

export class Buses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataBusesValue: [],
        };
        this.userService = new UserService();
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete(userNozzleId) {
        this.userService.deleteUserNozzle(userNozzleId)
            .then(user => {
                this.setState({
                    dataBusesValue: user.nozzles ? user.nozzles : [],
                });
            }).catch(error => {
                alert(error.message);
            });
    }

    componentDidMount() {
        this.userService.getCurrentUser()
            .then(user => {
                this.setState({
                    dataBusesValue: user.nozzles ? user.nozzles : [],
                });
            }).catch(error => {
                alert(error.message);
            });
    }

    render() {
        return (
            <div>
                <Messages ref={(el) => this.messages = el} />
                <Link to="/addNozzle">
                    <ButtonI18n i18nId="button.addNozzle" defaultMessage="Add Nozzle" />
                </Link>
                {this.state.dataBusesValue.map(nozzle => <Buse key={nozzle.userNozzleId} dataBusesValue={nozzle} deleteAction={() => this.handleDelete(nozzle.userNozzleId)}></Buse>)}
            </div>
        );
    }
}