import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormattedMessage } from 'react-intl';

class LoadingSpinner extends Component {

    render() {
        if (this.props.rendered) {
            return (<div>
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
                <FormattedMessage id={this.props.i18nId} defaultMessage="Loading ..." />
            </div>);
        }
        return "";
    }
}

export default LoadingSpinner;