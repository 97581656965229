import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import auth from '../../utils/auth';
import request from '../../utils/request';
import config from '../../config/config';
import { IntlProvider,FormattedMessage } from 'react-intl';
import InputTextI18n from '../../components/i18n/InputTextI18n';
import PasswordI18n from '../../components/i18n/PasswordI18n';
import ButtonI18n from '../../components/i18n/ButtonI18n';
import messages_fr from '../../translations/fr.json';
import messages_en from '../../translations/en.json';
const language = navigator.language.split(/[-_]/)[0];
const messages = {
  'fr': messages_fr,
  'en': messages_en
};

class AuthPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { value: {}, errors: [], didCheckErrors: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: { ...this.state.value, [event.target.name]: event.target.value }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const body = this.state.value;
    const requestURL = config.beLoginURL;

    request(requestURL, { method: 'POST', body: this.state.value })
      .then(response => {
        auth.setToken(response.token, body.rememberMe);
        auth.setUserInfo(response.user, body.rememberMe);
        this.redirectUser();
      })
      .catch(err => {
        this.messages.show({ severity: 'error', summary: <FormattedMessage id="login.error" defaultMessage="Invalid Username/Password" /> });
      });
  };

  redirectUser() {
    window.location = '/';
  };

  render() {
    
    return (
      <IntlProvider locale={language} key={language} messages={messages[language]}>        
      <div className="login-body" > 
        <div className="login-panel">
          <div className="p-grid">
            <Card>
              <form onSubmit={this.handleSubmit}>
                <div className="p-col-12 logo-container">
                  <h1><FormattedMessage id="login.title" defaultMessage="Login to Your Account" /></h1>
                  <Messages ref={(el) => this.messages = el} />
                </div>
                <div className="p-col-12">
                  <InputTextI18n i18nId="login.username.placeholder"
                    key="username"
                    name="username"
                    defaultMessage="Username"
                    value={this.state.value.username}
                    onChange={this.handleChange} />
                </div>
                <div className="p-col-12">
                  <PasswordI18n i18nId="login.password.placeholder"
                    defaultMessage="Password"
                    key="password"
                    feedback={false}
                    name="password"
                    value={this.state.value.password}
                    onChange={this.handleChange} />
                </div>
                <div className="p-col-12">
                  <ButtonI18n i18nId="login.signin.placeholder"
                    defaultMessage="Proceed"
                    type="submit"
                    className="button-auto" />
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
      </IntlProvider>
    );
  }
}

AuthPage.defaultProps = {};
AuthPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default AuthPage;