import React, { Component } from 'react';
import { PasserellesService } from '../service/PasserellesService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { InputMask } from 'primereact/inputmask';
import logger from '../config/logger';
import ButtonI18n from './i18n/ButtonI18n';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import auth from '../utils/auth';

const messages = defineMessages({
    title: {
        id: "gateways.list.title",
        defaultMessage: "List of Gateways"
    },
    sn: {
        id: "gateways.list.sn",
        defaultMessage: "Serial Number (SN)"
    },
    name: {
        id: "gateways.list.name",
        defaultMessage: "Name"
    },
    phoneNumber: {
        id: "gateways.list.phoneNumber",
        defaultMessage: "Phone Number"
    },
    gateway: {
        id: "commons.gateway",
        defaultMessage: "Gateway"
    }
});

class Passerelles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: []
        };
        this.passerellesService = new PasserellesService();
        this.save = this.save.bind(this);
        this.onPasserelleSelect = this.onPasserelleSelect.bind(this);
        this.addNewGateway = this.addNewGateway.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        const username = this.props.username ? this.props.username : auth.getUserInfo().username;
        this.passerellesService.getGatewaysForUser(username)
            .then((data) => {
                if (data) {
                    this.setState({
                        passerelles: data,
                    });
                } else {
                    this.messages.show({ severity: 'error', summary: 'ERREUR', detail: 'NO DATA', sticky: true });
                    this.setState({
                        passerelles: [],
                    });
                }
            }).catch((error) => {
                logger.error(error);
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    updateProperty(property, value) {
        let passerelle = this.state.passerelle;
        passerelle[property] = value;
        this.setState({ passerelle: passerelle });
    }

    onPasserelleSelect(e) {
        this.newPasserelle = false;
        this.setState({
            displayDialog: true,
            passerelle: { ...e.data }
        });
    }

    addNewGateway() {
        this.newPasserelle = true;
        this.setState({
            displayDialog: true,
            passerelle: { id: '', name: '' }
        });
    }

    save() {
        const username = this.props.username ? this.props.username : auth.getUserInfo().username;
        let passerelles = this.state.passerelles ? [...this.state.passerelles] : [];
        if (this.newPasserelle) {
            passerelles.push(this.state.passerelle);
        } else {
            passerelles[this.state.passerelles.indexOf(this.state.selectedPasserelle)] = this.state.passerelle;
        }

        this.passerellesService.save(passerelles, username)
            .then((data) => {
                if (data) {
                    this.setState({ passerelles: passerelles, selectedPasserelle: null, passerelle: null, displayDialog: false });
                } else {
                    this.messages.show({ severity: 'error', summary: 'ERREUR', detail: 'NO DATA', sticky: true });
                }
            }).catch((error) => {
                logger.error(error);
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    handleDelete() {
        const updatedGateways = this.state.passerelles.filter(p => p.id !== this.state.passerelle.id);
        this.passerellesService.save(updatedGateways)
        .then((data) => {
            if (data) {
                this.setState({ passerelles: updatedGateways, selectedPasserelle: null, passerelle: null, displayDialog: false });
            } else {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: 'NO DATA', sticky: true });
            }
        }).catch((error) => {
            logger.error(error);
            this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
        });
    }

    render() {
        const { formatMessage } = this.props.intl;

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={this.save} />
            <ButtonI18n i18nId="button.delete" defaultMessage="Delete" className="p-button-danger" style={{ float: 'left' }} onClick={this.handleDelete} disabled={this.newPasserelle} />
        </div>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <Messages ref={(el) => this.messages = el} />
                        <ButtonI18n i18nId="gateways.add" defaultMessage="Add a gateway" onClick={this.addNewGateway} />
                        <h5>
                            <FormattedMessage id="gateways.hint"
                                defaultMessage="Hint: Use a name that will help identify your gateway. (e.g. REEL-001)" />
                        </h5>
                        <DataTable value={this.state.passerelles}
                            paginatorPosition="both"
                            selectionMode="single"
                            header={formatMessage(messages.title)}
                            paginator={true} rows={10}
                            responsive={true}
                            selection={this.state.selectedPasserelle}
                            onSelectionChange={event => this.setState({ selectedPasserelle: event.value })}
                            onRowSelect={this.onPasserelleSelect}>
                            <Column field="id" header={formatMessage(messages.sn)} sortable={true} />
                            <Column field="name" header={formatMessage(messages.name)} sortable={true} />
                            <Column field="number" header={formatMessage(messages.phoneNumber)} sortable={true} />
                            <Column field="userNozzleId" header={<FormattedMessage id="commons.nozzle" defaultMessage="Nozzle" />} sortable={true} />
                        </DataTable>
                    </div>
                </div>
                <Dialog visible={this.state.displayDialog} width="300px" header={formatMessage(messages.gateway)} modal={true} footer={dialogFooter} onHide={() => this.setState({ displayDialog: false })}>
                    {
                        this.state.passerelle &&

                        <div className="p-grid p-fluid">
                            <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="Id"><FormattedMessage id="commons.SN" defaultMessage="(SN)" /></label></div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                                <InputText id="Id" onChange={(e) => { this.updateProperty('id', e.target.value) }} value={this.state.passerelle.id} />
                            </div>

                            <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="name"><FormattedMessage id="commons.name" defaultMessage="Name" /></label></div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                                <InputText id="name" onChange={(e) => { this.updateProperty('name', e.target.value) }} value={this.state.passerelle.name} />
                            </div>

                            <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="number"><FormattedMessage id="commons.number" defaultMessage="Number" /></label></div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                                <InputMask id="number" mask="999-999-9999" onChange={(e) => { this.updateProperty('number', e.target.value) }} value={this.state.passerelle.number} />
                            </div>

                            <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="number"><FormattedMessage id="commons.nozzle" defaultMessage="Nozzle" /></label></div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                                <InputText id="nozzle" onChange={(e) => { this.updateProperty('userNozzleId', e.target.value) }} value={this.state.passerelle.userNozzleId} />
                            </div>
                        </div>
                    }
                </Dialog>

            </div>
        );
    }
}

export default injectIntl(Passerelles);