import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';

export class TrajetsService {
    
    getAllTrajets() {
        return BaseServiceClient.get(config.bePathsURL);
    }

    getAllTrajetsFromDate(path,date) {    
        return BaseServiceClient.get(config.bePathURL(path, date));    
    }

    getStartPathInRange(sn, startTS, endTS) {
        return BaseServiceClient.get(config.bePathRangeURL(sn, startTS, endTS));
    }

    getPathPacketsInRange(sn, startTS, endTS) {
        return BaseServiceClient.get(config.bePathPacketsRangeURL(sn, startTS, endTS));
    }
}