import config from '../../config/config';
import appUtil from '../../utils/appUtil';

export default class GoogleMaps {

    constructor() {
        this.theMap = null;
        this.markers = [];
        this.bounds = null;
        this.mapId = 'mapId';
    }

    afficherCarte(b) {

        if (!window.google) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBTwW1ZNLLockxUERSIlt4gU2OuU-6Cnj8&callback=initMap`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);

            s.addEventListener('load', e => {
                this.createMap(b);
                this.onScriptLoad(b)
                this.centerMap();
            })

        } else {
            this.createMap(b);
            this.onScriptLoad(b)
            this.centerMap();
        }
    }

    createMap(data) {
        if (data) {
            this.theMap = new window.google.maps.Map(
                document.getElementById(this.mapId), {
                options: {
                    center: { lat: appUtil.lat(data.latitude), lng: appUtil.lng(data.longitude) },
                    streetViewControl: false, maxZoom: 20
                }, mapTypeId: window.google.maps.MapTypeId.SATELLITE

            });
        }
    }

    onScriptLoad(b) {
        while (this.markers && this.markers.length) {
            this.markers.pop().setMap(null);
        }
        switch (b.stateInt) {
            case 0:
                // idle
                this.createGreenMarker(b.latitude, b.longitude);
                break;
            case 1:
                // Waiting GPS
                this.createGreenMarker(b.latitude, b.longitude);
                break;
            case 2:
                // Ready to start               
                this.createRedMarker(b.latitude, b.longitude);
                this.createFlagMarker(b.endCoor.latitude, b.endCoor.longitude);
                break;
            case 3:
                // Running            
                this.createGreenMarker(b.startCoor.latitude, b.startCoor.longitude);
                this.createRedMarker(b.latitude, b.longitude);
                this.createFlagMarker(b.endCoor.latitude, b.endCoor.longitude);
                break;
            case 4:
                // Close to end                
                this.createGreenMarker(b.startCoor.latitude, b.startCoor.longitude);
                this.createRedMarker(b.latitude, b.longitude);
                this.createFlagMarker(b.endCoor.latitude, b.endCoor.longitude);
                break;
            default:
                // N/A
                // this.createGreenMarker(b.latitude, b.longitude);
                break;
        }

    }

    centerMap() {
        let i;
        this.bounds = new window.google.maps.LatLngBounds();

        for (i = 0; i < this.markers.length; i++) {
            this.bounds.extend(this.markers[i].getPosition());
        }
        this.theMap.setCenter(this.bounds.getCenter());
        setTimeout(function () { this.theMap.fitBounds(this.bounds); }.bind(this), 1);
    }

    createGreenMarker(lat, lng, label) {
        var iconGreen = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'green',
            fillOpacity: 1,
            strokeColor: 'green',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };
        if (lat && lng) {
            let greenMarker = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(lat),
                    lng: appUtil.lng(lng),
                },
                map: this.theMap,
                icon: iconGreen,
                label: label
            });
            this.markers.push(greenMarker);

        }
    }

    createYellowMarker(lat, lng) {
        var iconYellow = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'yellow',
            fillOpacity: 1,
            strokeColor: 'yellow',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };
        if (lat && lng) {
            let yellowMarker = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(lat),
                    lng: appUtil.lng(lng),
                },
                map: this.theMap,
                icon: iconYellow
            });
            this.markers.push(yellowMarker);
        }

    }

    createRedMarker(lat, lng) {
        var iconYellow = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'red',
            fillOpacity: 1,
            strokeColor: 'red',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };
        if (lat && lng) {
            let yellowMarker = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(lat),
                    lng: appUtil.lng(lng),
                },
                map: this.theMap,
                icon: iconYellow
            });
            this.markers.push(yellowMarker);
        }

    }

    createFlagMarker(lat, lng) {
        var iconFlag = config.iconBase + 'finish-flag.png';
        let flagMarker = new window.google.maps.Marker({
            position: {
                lat: appUtil.lat(lat),
                lng: appUtil.lng(lng),
            },
            map: this.theMap,
            icon: iconFlag
        });
        this.markers.push(flagMarker);
    }

    createPolyline(coordinates) {
        let coorFormatted = coordinates.map(c => { return { lat: appUtil.lat(c.lat), lng: appUtil.lng(c.lng) } });
        const flightPath = new window.google.maps.Polyline({
            path: coorFormatted,
            geodesic: true,
            strokeColor: "#0000FF",
            strokeOpacity: 1.0,
            strokeWeight: 5,
        });
        flightPath.setMap(this.theMap);
    }
}