import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import ButtonI18n from '../i18n/ButtonI18n';
import { Messages } from 'primereact/messages';
import LoadingSpinner from '../LoadingSpinner';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import UserService from '../../service/UserService';
import GoogleMaps from '../map/GoogleMaps';

let messages;
const userService = new UserService();
const mapObject = new GoogleMaps();

export const EditSurface = (props) => {
    const history = useHistory();

    const id = props.match.params.id;
    const [description, setDescription] = useState('');
    const [fieldNumber, setFieldNumber] = useState('');
    const [surfaceNumber, setSurfaceNumber] = useState('');
    const [startPosition, setStartPosition] = useState('');
    const [endPosition, setEndPosition] = useState('');
    const [surfaceWidth, setSurfaceWidth] = useState('');
    const [saving, setSaving] = useState(false);

    const onDescriptionChange = e => setDescription(e.target.value);
    const onFieldNumberChange = e => setFieldNumber(e.target.value);
    const onSurfaceNumberChange = e => setSurfaceNumber(e.target.value);
    const onStartPositionChange = e => setStartPosition(e.target.value);
    const onEndPositionChange = e => setEndPosition(e.target.value);
    const onSurfaceWidthChange = e => setSurfaceWidth(e.target.value);

    const loadUser = async () => {
        const user = await userService.getCurrentUser();
        const [surface] = user.surfaces.filter(s => s.id === id);
        setDescription(surface.description);
        setFieldNumber(surface.fieldNumber);
        setSurfaceNumber(surface.surfaceNumber);
        setStartPosition(`[${surface.startPosition.latitude}, ${surface.startPosition.longitude}]`);
        setEndPosition(`[${surface.endPosition.latitude}, ${surface.endPosition.longitude}]`);
        setSurfaceWidth(surface.surfaceWidth);
        mapObject.afficherCarte({
            latitude: surface.startPosition.latitude,
            longitude: surface.startPosition.longitude,
            endCoor: {
                latitude: surface.endPosition.latitude,
                longitude: surface.endPosition.longitude
            },
            stateInt: 2
        });
        mapObject.createPolyline([
            {
                lat: surface.startPosition.latitude,
                lng: surface.startPosition.longitude
            },
            {
                lat: surface.endPosition.latitude,
                lng: surface.endPosition.longitude
            }
        ]);

    };

    useEffect(() => {
        loadUser();
        return () => { };
    }, []);

    const onSubmit = e => {
        e.preventDefault();
        setSaving(true);

        userService.getCurrentUser()
            .then(user => {
                const surface = {
                    id: id,
                    description: description,
                    fieldNumber: fieldNumber,
                    surfaceNumber: surfaceNumber,
                    startPosition: props.location.state.selectedRun.startPosition,
                    endPosition: props.location.state.selectedRun.endPosition,
                    surfaceWidth: surfaceWidth
                };

                if (user.surfaces) {
                    let filteredSurfaces = user.surfaces.filter(s => s.id !== id);
                    filteredSurfaces.push(surface);
                    user.surfaces = filteredSurfaces;
                } else {
                    user.surfaces = [surface];
                }
                return userService.save(user);
            }).then(savedUser => {
                messages.clear();
                messages.show({ severity: 'info', summary: 'Success', detail: description + ' saved', sticky: true });
                setSaving(false);
                history.push('/surfaces');
            }).catch(error => {
                messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
                setSaving(false);
            });
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <LoadingSpinner rendered={saving} i18nId="spinner.saving" />
                    <Messages ref={(el) => messages = el} />
                    <div>
                        <p className="p-float-label">
                            <InputText id="surfaceId" value={id} disabled />
                            <label htmlFor="surfaceId"><FormattedMessage id="surface.id" defaultMessage="ID" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="description" value={description} onChange={onDescriptionChange} />
                            <label htmlFor="description"><FormattedMessage id="surface.description" defaultMessage="Description" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="fieldNumber" value={fieldNumber} onChange={onFieldNumberChange} />
                            <label htmlFor="fieldNumber"><FormattedMessage id="surface.fieldNumber" defaultMessage="Field Number" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="surfaceNumber" value={surfaceNumber} onChange={onSurfaceNumberChange} />
                            <label htmlFor="surfaceNumber"><FormattedMessage id="surface.surfaceNumber" defaultMessage="Surface Number" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="startPosition" value={startPosition} onChange={onStartPositionChange} disabled />
                            <label htmlFor="startPosition"><FormattedMessage id="surface.startPosition" defaultMessage="Start Position" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="endPosition" value={endPosition} onChange={onEndPositionChange} disabled />
                            <label htmlFor="endPosition"><FormattedMessage id="surface.endPosition" defaultMessage="End Position" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="surfaceWidth" value={surfaceWidth} onChange={onSurfaceWidthChange} />
                            <label htmlFor="surfaceWidth"><FormattedMessage id="surface.surfaceWidth" defaultMessage="Surface Width (m)" /></label>
                        </p>
                        <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={onSubmit} />
                    </div>
                </div>
                <div className="card p-col">
                    <div id="mapId" style={{ width: '100%', height: '500px' }}>
                    </div>
                </div>
            </div>
        </div>
    );
};