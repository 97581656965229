import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';

class PhoneNumberService {

    getPhonesPage(pageSize, pageNumber, phoneFilter) {
        return BaseServiceClient.get(config.beAdminPhone(pageSize, pageNumber, phoneFilter));
    }

    updatePhoneSmsUrl(sid, smsUrl) {
        return BaseServiceClient.post(config.beAdminUpdatePhone(sid), {smsUrl: smsUrl});
    }
}

export default PhoneNumberService;