import React from 'react';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import { PasserellesService } from '../service/PasserellesService';
import CommandMessageService from '../service/CommandMessageService';
import ButtonI18n from './i18n/ButtonI18n';
import { Dialog } from 'primereact/dialog'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
    newPoint: {
        id: "control.newPoint",
        defaultMessage: "New Point"
    },
    smsSuccess: {
        id:"control.smsSuccess",
        defaultMessage: "Nouveau point de FIN envoyé. *Veuillez valider le nouveau point."
    },
    smsSuccessValidate: {
        id:"control.smsSuccess.validate",
        defaultMessage:"Veuillez attendre le SMS de confirmation «Prêt à irriguer». Ensuite, utilisez le menu «État» afin de valider la nouvelle position de FIN représentée par un drapeau noir sur la carte."
    }
});

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

class ControlView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            latitude: '',
            longitude: '',
            mapLink: '',
            number: 'invalid',
            smsCommand: ''
        };
        this.passerellesService = new PasserellesService();
        this.commandMessageService = new CommandMessageService();
        this.geoSuccess = this.geoSuccess.bind(this);
        this.geoError = this.geoError.bind(this);
        this.refreshCurrentPosition = this.refreshCurrentPosition.bind(this);
        this.handleWarningMessage = this.handleWarningMessage.bind(this);
        this.handleGatewaySelection = this.handleGatewaySelection.bind(this);
        this.getCurrentPosition = this.getCurrentPosition.bind(this);
        this.sendSmsCommand = this.sendSmsCommand.bind(this);
    }

    sendSmsCommand() {
        const phoneNumber = '1' + this.state.gateway.number.replace(/-/g, "");
        // this.commandMessageService.sendSms = () => new Promise((resolve) => resolve('test'));
        this.commandMessageService.sendSms(phoneNumber, this.state.smsCommand)
            .then(result => {
                const { formatMessage } = this.props.intl;
                this.messages.show({ severity: 'info', summary: formatMessage(messages.smsSuccess), detail: formatMessage(messages.smsSuccessValidate), sticky: true });
                window.scrollTo(0, 0);
                this.setState({displayDialog: false});
            }).catch(error => {
                this.messages.show({ severity: 'error', summary: 'Erreur', detail: error.message, sticky: true });
            });
    }

    geoSuccess(position, infoWindow, map) {
        const { formatMessage } = this.props.intl;
        var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        };

        infoWindow.setPosition(pos);
        infoWindow.setContent(formatMessage(messages.newPoint));
        infoWindow.open(map);
        map.setCenter(pos);

        let lat = Math.abs(position.coords.latitude);
        let lng = Math.abs(position.coords.longitude);

        let latDirection = position.coords.latitude < 0 ? 'S' : 'N';
        let lngDirection = position.coords.longitude < 0 ? 'W' : 'E';

        let latDeg = Math.trunc(lat).toString().padStart(2, '0');
        let lngDeg = Math.trunc(lng).toString().padStart(3, '0');

        let latMin = ((lat - Math.trunc(lat)) * 60).toFixed(4).padStart(7, '0');
        let lngMin = ((lng - Math.trunc(lng)) * 60).toFixed(4).padStart(7, '0');

        let latitude = (latDeg + latMin + latDirection);
        let longitude = (lngDeg + lngMin + lngDirection);

        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            number: `+1-${this.state.gateway ? this.state.gateway.number : ''}`,
            smsCommand: `GPS-${latitude},${longitude}` // GPS-4650.3211N,07120.0245W
        });
    };

    geoError(error) {
        this.messages.show({ severity: 'error', summary: 'Erreur GPS', detail: error.message, sticky: true });
    };

    handleWarningMessage() {
        this.messages.show({ severity: 'warn', summary: 'Attention', detail: 'Vous devez accepter de partager votre position GPS.', sticky: true });
    }

    refreshCurrentPosition() {
        if (!this.state.gateway) {
            return this.messages.show({ severity: 'error', summary: 'Obligatoire', detail: "Séletionnez une passerelle", sticky: true });
        }
        if (navigator.geolocation) {
            // navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError);
            this.getCurrentPosition();
        }
        else {
            alert('Votre navigateur ou votre système d\'exploitation ne supporte pas la géolocalisation');
        }
    }

    getCurrentPosition() {
        var map = new window.google.maps.Map(document.getElementById('mapId'), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 18,

            mapTypeId: window.google.maps.MapTypeId.SATELLITE
        });
        var infoWindow = new window.google.maps.InfoWindow();

        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(p => this.geoSuccess(p, infoWindow, map), function () {
                this.handleLocationError(true, infoWindow, map.getCenter(), map);
            });
        } else {
            // Browser doesn't support Geolocation
            this.handleLocationError(false, infoWindow, map.getCenter(), map);
        }
    }

    componentDidMount() {
        this.passerellesService.getAllPasserelles()
            .then((data) => {
                this.getCurrentPosition();
                this.setState({ gateways: data, gateway: data[0] });
            })
            .catch((error) => {
                this.messages.show({ severity: 'error', summary: 'Impossible de récupérer les passerelles', detail: JSON.stringify(error), sticky: true });
            });
    }

    handleLocationError(browserHasGeolocation, infoWindow, pos, map) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(browserHasGeolocation ?
            'Error: The Geolocation service failed.' :
            'Error: Your browser doesn\'t support geolocation.');
        infoWindow.open(map);
    }

    handleGatewaySelection(e) {
        this.setState({ gateway: e.value, number: `+1-${e.value.number}` });
    }

    render() {
        const mapLat = this.state.latitude;
        const mapLng = this.state.longitude;
        const mapLinkUrl = 'https://maps.google.com/maps?&z=15&q=' + mapLat + '+' + mapLng + '&ll=' + mapLat + '+' + mapLng;
        let smsRef = '';
        // Important note: For iOS, the preceding char of the body is a semicolon
        if (getMobileOperatingSystem() === 'iOS') {
            smsRef = `sms:${this.state.number};body=${this.state.smsCommand}`
        } else {
            smsRef = `sms:${this.state.number}?body=${this.state.smsCommand}`
        }
        const dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <ButtonI18n i18nId="button.IAgree" defaultMessage="I Agree" className="p-button-rounded  p-button-success" onClick={this.sendSmsCommand} />
        </div>;
        return (
            <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="card">
                        <h1><FormattedMessage id="control.title" defaultMessage="Define a new END coordinate" /></h1>
                        <Messages ref={(el) => this.messages = el} />
                        <div className="p-col">
                            <FormattedMessage id="control.selectDevice" defaultMessage="Select a device:" />
                        </div>
                        <div className="p-col">
                            <Dropdown value={this.state.gateway} options={this.state.gateways} onChange={this.handleGatewaySelection} placeholder={<FormattedMessage id="state.selectgateway" defaultMessage="Select a gateway" />} optionLabel="name" />
                        </div>
                        <div className="p-col">
                            <ButtonI18n i18nId="button.refresh" defaultMessage="Refresh" onClick={this.refreshCurrentPosition} />
                        </div>
                        <h3>Latitude: {this.state.latitude}</h3>
                        <h3>Longitude: {this.state.longitude}</h3>
                        <h3>
                            <FormattedMessage id="control.command" defaultMessage="Command:" />:
                            <a rel="noopener noreferrer">&#160;{this.state.smsCommand}</a>
                        </h3>
                        <div className="p-col">
                            <ButtonI18n i18nId="control.sendSms" defaultMessage="Notify Gateway by SMS" className="p-button-rounded  p-button-success" onClick={() => this.setState({ displayDialog: true })} />
                        </div>
                        <div className="card p-col">
                            <div id="mapId" style={{ width: '100%', height: '500px' }} />
                        </div>
                    </div>
                </div>
                <Dialog visible={this.state.displayDialog} width="300px" header={<FormattedMessage id="control.confirmSmsTitle" defaultMessage="Attention" />} modal={true} footer={dialogFooter} onHide={() => this.setState({ displayDialog: false })}>
                    <div className="p-grid p-fluid">
                        <p>
                            <FormattedMessage id="control.confirmSms" defaultMessage="En définissant un point de fin de trajet manuellement, vous avez la responsabilité de vous assurer que le point est au bon endroit." />
                        </p>
                        <p><strong>
                            <FormattedMessage id="control.confirmSms.tlNoResponsibility" defaultMessage="Trilogik n'est pas responsable des dommages causés par un mauvais point de FIN." />
                        </strong></p>
                        <p>
                            <FormattedMessage id="control.confirmSms.agreementIndication" defaultMessage="Pour accepter, cliquez sur « J'accepte »" />
                        </p>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default injectIntl(ControlView);