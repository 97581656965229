import React, { Component } from 'react';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppInlineProfile } from './AppInlineProfile';
import { Route } from 'react-router-dom';
import { EtatHistorique } from './components/EtatHistorique';
import { EtatSystems } from './components/EtatSystems';
import { Alertes } from './components/Alertes';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import Passerelles from './components/Passerelles'
import Trajet from './components/Trajet';
import { Buses } from './components/Buses';
import Fabs from './components/Fabs';
import AddNozzle from './components/AddNozzle';
import { VersionsView } from './components/Versions';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'fullcalendar/dist/fullcalendar.css';
import './layout/layout.css';
import './App.css';
import { EtatSys } from './components/EtatSys';
import ControlView from './components/ControlView';
import config from './config/config';
import auth from './utils/auth';
import { defineMessages, injectIntl } from 'react-intl';
// import { Dropdown } from 'primereact/dropdown';
import CheckoutWrapper from './components/CheckoutWrapper';
import Users from './components/Users';
import AddUser from './components/AddUser';
import UserWrapper from './components/UserWrapper';
import CommandMessage from './components/CommandMessage';
import SimpleForm from './components/templates/SimpleForm';
import PrimeForm from './components/templates/PrimeForm';
import { AddSurface } from './components/surface/AddSurface';
import Surfaces from './components/surface/Surfaces';
import { EditSurface } from './components/surface/EditSurface';
import SurfaceRuns from './components/surface/SurfaceRuns';
import LastPacketList from './components/LastPacketList';
import TrackRunList from './components/track/TrackRunList';
import Gateways from './components/admin/Gateways';
import IncomingPhoneNumbers from './components/admin/IncomingPhoneNumbers';

const messages = defineMessages({
    gateways: {
        id: "menu.gateways",
        defaultMessage: "Gateways"
    },
    state: {
        id: "menu.state",
        defaultMessage: "State"
    },
    tracks: {
        id: "menu.tracks",
        defaultMessage: "Tracks"
    },
    controls: {
        id: "menu.controls",
        defaultMessage: "Controls"
    },
    english: {
        id: "language.english",
        defaultMessage: "English"
    },
    french: {
        id: "language.french",
        defaultMessage: "French"
    },
    changeLanguage: {
        id: "language.change",
        defaultMessage: "Change language"
    },
    nozzles: {
        id: "menu.nozzles",
        defaultMessage: "Sprinkler Chart"
    },
    surfaces: {
        id: "surfaces.title",
        defaultMessage: "Surfaces"
    },
    trackRunList: {
        id: "track.runs",
        defaultMessage: "Track Runs"
    }
});


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            user: auth.getUserInfo(),
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.createMenu();
    }


    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
        setTimeout(() => { this.layoutMenuScroller.moveBar(); }, 500);
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
        const { formatMessage } = this.props.intl;
        this.menu = [
            { label: formatMessage(messages.gateways), icon: 'pi pi-fw pi-wifi', to: '/passerelles' },
            { label: formatMessage(messages.state), icon: 'pi pi-fw pi-chart-bar', to: '/etatSystems' },
            // { label: 'Historique', icon: 'pi pi-fw pi-bars', to: '/etatHistorique' },
            { label: formatMessage(messages.tracks), icon: 'pi pi-fw pi-globe', to: '/trajet' },
            { label: formatMessage(messages.trackRunList), icon: 'pi pi-fw pi-cog', to: '/track/runs' },
            { label: formatMessage(messages.surfaces), icon: 'pi pi-fw pi-globe', to: '/surfaces' },
            { label: formatMessage(messages.nozzles), icon: 'pi pi-fw pi-cloud', to: '/buses' },
            //{label: 'Alertes', icon: 'pi pi-fw pi-home', badge: '9', to: '/alertes'},
            { label: formatMessage(messages.controls), icon: 'pi pi-fw pi-cog', to: '/control' },
            // { label: 'Checkout', icon: 'pi pi-fw pi-shopping-cart', to: '/checkout' },
        ];
        const adminMenu = {
            label: 'Administration', icon: 'pi pi-fw pi-cog',
            items: [
                { label: 'Users', icon: 'pi pi-fw pi-users', to: '/users' },
                { label: 'Add User', icon: 'pi pi-fw pi-user-plus', to: '/addUser' },
                { label: 'Phone Numbers', icon: 'pi pi-fw pi-cog', to: '/admin/phones' },
                { label: 'SCMD', icon: 'pi pi-fw pi-envelope', to: '/scmd' },
                { label: 'Last Packets', icon: 'pi pi-fw pi-envelope', to: '/admin/lp' }
            ]
        }
        if (auth.getUserInfo().username === 'admin') {
            this.menu.push(adminMenu);
        }
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    changeLanguage(i) {
        this.props.onChangeLanguage(i);

    }
    render() {
        // const { formatMessage } = this.props.intl;
        let logo = this.state.layoutColorMode === 'dark' ? '/assets/layout/images/logo-white.png' : '/assets/layout/images/logo.png';

        let wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });
        let sidebarClassName = classNames("layout-sidebar", { 'layout-sidebar-dark': this.state.layoutColorMode === 'dark' });

        // let lbllanguage = [
        //     { label: formatMessage(messages.french), value: 'fr' },
        //     { label: formatMessage(messages.english), value: 'en' }
        // ];

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <AppTopbar onToggleMenu={this.onToggleMenu} />


                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

                    <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                        <div className="layout-sidebar-scroll-content" >
                            <div className="layout-logo">
                                <img alt="Logo" src={logo} height="49px" width="175px" />
                            </div>
                            <AppInlineProfile user={this.state.user} />
                            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                        </div>
                    </ScrollPanel>
                </div>

                <div className="layout-main">
                    <Route path="/" exact component={EtatSystems} />
                    <Route path="/etatSystems" component={EtatSystems} />
                    <Route path="/passerelles" component={Passerelles} />
                    <Route path="/etatHistorique" component={EtatHistorique} />
                    <Route path="/alertes" component={Alertes} />
                    <Route path="/trajet" component={Trajet} />
                    <Route path="/buses" component={Buses} />
                    <Route path="/fabs" component={Fabs} />
                    <Route path="/addNozzle" component={AddNozzle} />
                    <Route path="/etatSys" component={EtatSys} />
                    <Route path="/control" component={ControlView} />
                    <Route path="/versions" component={VersionsView} />
                    <Route path="/checkout" component={CheckoutWrapper} />
                    <Route path="/users" component={Users} />
                    <Route path="/addUser" component={AddUser} />
                    <Route path="/user" component={UserWrapper} />
                    <Route path="/scmd" component={CommandMessage} />
                    <Route path="/template/simpleForm" component={SimpleForm} />
                    <Route path="/template/primeForm" component={PrimeForm} />
                    <Route path="/surface/addSurface" component={AddSurface} />
                    <Route path="/surface/editSurface/:id" component={EditSurface} />
                    <Route path="/surfaces" component={Surfaces} />
                    <Route path="/surface/runs" component={SurfaceRuns} />
                    <Route path="/admin/lp" component={LastPacketList} />
                    <Route path="/track/runs" component={TrackRunList} />
                    <Route path="/admin/gateways" component={Gateways} />
                    <Route path="/admin/phones" component={IncomingPhoneNumbers} />
                </div>

                <AppFooter version={config.version} />

                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default injectIntl(App);
