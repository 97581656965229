import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserService from '../../service/UserService';
import { Link } from 'react-router-dom';
import { Messages } from 'primereact/messages';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import GoogleMaps from '../map/GoogleMaps';

class Surfaces extends Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            surfaces: []
        };
        this.userService = new UserService();
        this.descriptionTemplate = this.descriptionTemplate.bind(this);
        this.startPositionTemplate = this.startPositionTemplate.bind(this);
        this.endPositionTemplate = this.endPositionTemplate.bind(this);
        this.deleteUserSurface = this.deleteUserSurface.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.confirmDeleteProduct = this.confirmDeleteProduct.bind(this);
        this.hideDeleteProductDialog = this.hideDeleteProductDialog.bind(this);
        this.theMap = new GoogleMaps();
    }

    componentDidMount() {
        this.userService.getCurrentUser()
            .then((user) => {
                this.setState({ surfaces: user.surfaces });
                this.updateMapView(user);
            })
            .catch((error) => {
                console.error(error);
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    updateMapView(user) {
        if (!user.surfaces || user.surfaces.length <= 0) {
            return;
        }
        
        this.theMap.afficherCarte({
            latitude: user.surfaces[0].startPosition.latitude,
            longitude: user.surfaces[0].startPosition.longitude,
            endCoor: {
                latitude: user.surfaces[0].endPosition.latitude,
                longitude: user.surfaces[0].endPosition.longitude
            }
        });
        for (let surface of user.surfaces) {
            let slat = surface.startPosition.latitude;
            let slng = surface.startPosition.longitude;
            let elat = surface.endPosition.latitude;
            let elng = surface.endPosition.longitude;
            this.theMap.createGreenMarker(slat, slng, {text: surface.description, color: '#FFFFFF'});
            this.theMap.createFlagMarker(elat, elng);
            this.theMap.createPolyline([{ lat: slat, lng: slng }, { lat: elat, lng: elng }]);
        }
        this.theMap.centerMap();
    }

    descriptionTemplate(rowData, column) {
        return <Link
            to={{
                pathname: `/surface/editSurface/${rowData.id}`,
                state: { selectedRun: rowData }
            }}
        > {rowData.description}</Link >;
    }

    startPositionTemplate(rowData, column) {
        return <div>{rowData.startPosition.latitude}<br />{rowData.startPosition.longitude}</div>
    }

    endPositionTemplate(rowData, column) {
        return <div>{rowData.endPosition.latitude}<br />{rowData.endPosition.longitude}</div>
    }

    runsTemplate(rowData, column) {
        return (
            <Link to={{ pathname: "/surface/runs", state: { surface: rowData } }}>
                <FormattedMessage id="surfaces.stats" defaultMessage="Stats" />
            </Link>
        );
    }

    deleteTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    confirmDeleteProduct(product) {
        this.setState({
            product,
            deleteProductDialog: true
        });
    }

    hideDeleteProductDialog() {
        this.setState({ deleteProductDialog: false });
    }

    deleteUserSurface() {
        this.setState({ loading: true });
        this.userService.deleteUserSurface(this.state.product.id)
            .then((user) => {
                this.setState({
                    loading: false,
                    surfaces: user.surfaces,
                    deleteProductDialog: false
                });
                this.updateMapView(user);
                this.messages.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error(error);
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
                this.setState({
                    loading: false,
                    deleteProductDialog: false
                });
            });
    }

    render() {
        const deleteProductDialogFooter = (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteProductDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteUserSurface} />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <LoadingSpinner rendered={this.state.loading} i18nId="spinner.saving" />
                        <Messages ref={(el) => this.messages = el} />
                        <DataTable value={this.state.surfaces} responsive={true} header={<FormattedMessage id="surfaces.title" defaultMessage="Surfaces" />} emptyMessage="No surfaces">
                            <Column field="id" header="ID" />
                            <Column header={<FormattedMessage id="surface.description" defaultMessage="Description" />} body={this.descriptionTemplate} />
                            <Column field="fieldNumber" header={<FormattedMessage id="surface.fieldNumber" defaultMessage="Field Number" />} />
                            <Column field="surfaceNumber" header={<FormattedMessage id="surface.surfaceNumber" defaultMessage="Surface Number" />} />
                            <Column header={<FormattedMessage id="surfaces.startpt" defaultMessage="START" />} body={this.startPositionTemplate} />
                            <Column header={<FormattedMessage id="surfaces.endpt" defaultMessage="END" />} body={this.endPositionTemplate} />
                            <Column field="surfaceWidth" header={<FormattedMessage id="surface.surfaceWidth" defaultMessage="Surface Width (m)" />} />
                            
                            <Column body={this.deleteTemplate} />
                        </DataTable>

                        <Dialog visible={this.state.deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={this.hideDeleteProductDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.product && <span>Are you sure you want to delete <b>{this.state.product.description}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                    <div className="card p-col">
                        <div id="mapId" style={{ width: '100%', height: '500px' }}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Surfaces;