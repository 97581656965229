import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';

export class CheckoutService {

    applyPayment(token) {
        return BaseServiceClient.post(config.charge, { token: token });
    }

    checkout(username) {
        return BaseServiceClient.get(config.checkout(username));
    }
}

export default CheckoutService;