import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import ButtonI18n from './i18n/ButtonI18n';
import { Messages } from 'primereact/messages';
import CommandMessageService from '../service/CommandMessageService'

class CommandMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serialNumber: '',
            sms: '',
            timeout: '',
            from: ''
        };
        this.send = this.send.bind(this);
        this.commandMessageService = new CommandMessageService();
    }

    send() {
        this.commandMessageService.send(this.state.serialNumber, this.state.sms, this.state.timeout, this.state.from)
            .then(result => {
                this.messages.show({ severity: 'info', summary: 'Success', detail: `Message Sent: ${this.state.serialNumber}:${this.state.sms}:${this.state.timeout}`, sticky: false });
            })
            .catch(error => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: false });
            });
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Messages ref={(el) => this.messages = el} />
                        <h1>Send Message to Gateway</h1>
                        <p className="p-float-label">
                            <InputText id="serialNumber" value={this.state.serialNumber} onChange={(e) => this.setState({ serialNumber: e.target.value })} />
                            <label htmlFor="serialNumber">Serial Number</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="sms" value={this.state.sms} onChange={(e) => this.setState({ sms: e.target.value })} />
                            <label htmlFor="sms">Message</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="from" value={this.state.from} onChange={(e) => this.setState({ from: e.target.value })} />
                            <label htmlFor="from">From Phone Number</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="timeout" value={this.state.timeout} onChange={(e) => this.setState({ timeout: e.target.value })} />
                            <label htmlFor="timeout">Timeout</label>
                        </p>
                        <ButtonI18n i18nId="commons.send" defaultMessage="Send" icon="pi pi-check" onClick={this.send} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CommandMessage;