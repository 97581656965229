import React, {Component} from 'react';
import UserList from './UserList';

class Users extends Component {

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <UserList />
                    </div>
                </div>
            </div>
        );
    }
}

export default Users;