import React, { Component } from 'react';
import ButtonI18n from './i18n/ButtonI18n';

/**
 * @param dataBusesValue is the nozzle object (same as the database structure into table RI500nozzle)
 */
class Buse extends Component {

    render() {
        let pressions = [];
        let l = 0;
        if (this.props.dataBusesValue.pressure_psi) {
            l = this.props.dataBusesValue.pressure_psi.length;
        } else if (this.props.dataBusesValue.pressure_kg_per_cm2) {
            l = this.props.dataBusesValue.pressure_kg_per_cm2.length;
        }
        for (let i = 0; i < l; i++) {
            let p = {};
            if (this.props.dataBusesValue.pressure_psi) {
                p.pression = this.props.dataBusesValue.pressure_psi[i];
            } else if (this.props.dataBusesValue.pressure_kg_per_cm2) {
                p.pression = this.props.dataBusesValue.pressure_kg_per_cm2[i];
            }
            p.portee = this.props.dataBusesValue.jetlength_m[i];
            p.debit = this.props.dataBusesValue.debit_m3_per_h[i];
            pressions.push(p);
        }

        const buseData = {
            id: this.props.dataBusesValue.userNozzleId,
            nom: this.props.dataBusesValue.name,
            pressions: pressions,
        };

        return (

            <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="card  p-col">
                        <h1>
                            Buse #{buseData.id}: {buseData.nom}
                            <ButtonI18n i18nId="button.delete" defaultMessage="Delete" className="p-button-danger" style={{ float: 'right' }} onClick={this.props.deleteAction} />
                        </h1>

                        <div id="idShow" className="p-grid">
                            <div className="p-col-2"><h5>Numéro</h5><span>{buseData.id}</span></div>
                            <div className="p-col-10">
                                <div className="p-grid">
                                    <div className="p-col-4"><h5>Pression (psi)</h5></div>
                                    <div className="p-col-4"><h5>Porté (m)</h5></div>
                                    <div className="p-col-4"><h5>Débit (m³/h)</h5></div>
                                    {buseData.pressions.map(data => <>
                                        <div className="p-col-4"><span>{data.pression}</span></div>
                                        <div className="p-col-4"><span>{data.portee}</span></div>
                                        <div className="p-col-4"><span>{data.debit}</span></div>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Buse;