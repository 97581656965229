import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';

class CommandMessageService {

    send(serialNumber, message, timeout, from) {
        const body = {
            sn: serialNumber,
            m: message,
            to: timeout,
            f: from
        }
        return BaseServiceClient.post(config.beSCMD, body);
    }

    sendSms(phoneNumber, message) {
        const body = {
            m: message
        }
        return BaseServiceClient.post(config.beSms(phoneNumber), body);
    }
}

export default CommandMessageService;