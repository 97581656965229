import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import AppWrapper from './AppWrapper';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

ReactDOM.render(

    <HashRouter>
        <ScrollToTop>
            <AppWrapper></AppWrapper>
        </ScrollToTop>
    </HashRouter>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();