import React, { Component } from 'react';
import auth from './utils/auth';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export class AppInlineProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);
        this.logout = this.logout.bind(this);
    }

    onClick(event) {
        this.setState({ expanded: !this.state.expanded });
        event.preventDefault();
    }

    logout(event) {
        auth.logout();
    }

    render() {
        return (
            <div className="profile">
                <button className="p-link profile-link" onClick={this.onClick}>
                    <div>
                        <img src="assets/layout/images/avatar_4.png" alt="" />
                    </div>
                    <span className="username">{this.props.user.firstname}</span>
                    <i className="pi pi-fw pi-cog" />
                </button>
                <ul className={classNames({ 'profile-expanded': this.state.expanded })}>
                    <div style={{ display: 'none' }}>
                        <li><button className="p-link"><i className="pi pi-fw pi-user" /><span>Account</span></button></li>
                        <li><button className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
                    </div>
                    <li><button onClick={this.logout} className="p-link"><i className="pi pi-fw pi-power-off" /><span><FormattedMessage id="commons.logout" defaultMessage="Logout" /></span></button></li>
                </ul>
            </div>
        );
    }
}