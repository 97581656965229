import axios from 'axios';
import packageJson from '../../package.json';
global.appVersion = packageJson.version;
global.validateVersionIds = [];

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
const isDev = Boolean(
    window.location.hostname.startsWith('dev.')
);
const localhost = 'http://localhost:3001';
const api = isDev ? 'https://apidev.ri-500.ca' : 'https://api.ri-500.ca';
const apiBaseURL = isLocalhost ? localhost : api;
const apiVersion = '/ri/v1';
const checkoutVersion = '/co/v1';

/**
 * Start validate version
 * 
 * @param {*} delay interval delay to validate version (default 24h)
 */
function startValidateVersion(delay = 86400000) {
    const id = setInterval(() => isNewVersion(delay), delay);
    global.validateVersionIds.push(id);
    return global.validateVersionIds;
}

function stopValidateVersion(intervalId) {
    clearInterval(intervalId);
    const index = global.validateVersionIds.indexOf(intervalId);
    if (index > -1) {
        global.validateVersionIds.splice(index, 1);
    }
    return global.validateVersionIds;
}

function isNewVersion(delay) {
    axios.get('/meta.json')
        .then(res => {
            if (res && res.data && res.data.version !== config.version) {
                console.log(`version mismatch - global:${global.appVersion}, rest-api:${res.data.webapp}, config:${config.version}, delay:${delay}`);
                console.log('Clearing cache and hard reloading...')
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) caches.delete(name);
                        // delete browser cache and hard reload
                        window.location.reload(true);
                    });
                } else {
                    // delete browser cache and hard reload
                    window.location.reload(true);
                }

            }
        }).catch(error => { console.error(error) });
}

const config = {
    version: global.appVersion,
    isLocalhost: isLocalhost,
    beRootURL: apiBaseURL,
    beLoginURL: apiBaseURL + '/login',
    beStateURL: apiBaseURL + apiVersion + '/state',
    beStatesURL: apiBaseURL + apiVersion + '/states',
    bePathsURL: 'assets/demo/data/trajets.json',
    bePathURL: (sn, date) => { return apiBaseURL + apiVersion + '/path/' + sn + '/' + date; },
    bePathRangeURL: (sn, startTS, endTS) => { return apiBaseURL + apiVersion + '/path/' + sn + '/' + startTS + '/' + endTS; },
    bePathPacketsRangeURL: (sn, startTS, endTS) => { return apiBaseURL + apiVersion + '/path/packets/' + sn + '/' + startTS + '/' + endTS; },
    beGatewaysURL: apiBaseURL + apiVersion + '/gates',
    beGatewayURL: (id) => { return apiBaseURL + apiVersion + '/gate/' + id },
    iconBase: '/assets/layout/images/',
    maximum_google_map_markers: 50,
    beUserURL: apiBaseURL + apiVersion + '/user',
    beAddUserURL: apiBaseURL + apiVersion + '/user/add',
    beListUsersURL: apiBaseURL + apiVersion + '/users',
    beGetUserURL: (username) => { return apiBaseURL + apiVersion + '/user?u=' + username },
    charge: apiBaseURL + apiVersion + '/charge',
    checkout: (username) => { return apiBaseURL + checkoutVersion + '/checkout/' + username },
    beSCMD: apiBaseURL + apiVersion + '/scmd',
    beNozzleFabURL: apiBaseURL + apiVersion + '/nozzle/fab',
    beCustomNozzleURL: apiBaseURL + apiVersion + '/nozzle/custom',
    beSms: (phoneNumber) => { return apiBaseURL + apiVersion + '/sms/' + phoneNumber},
    beAcLastPackets: apiBaseURL + apiVersion + '/admin/ac/lp',
    beIrLastPackets: apiBaseURL + apiVersion + '/admin/ri/lp',
    beIrListGateways: apiBaseURL + apiVersion + '/admin/gates',
    beAdminGateway: apiBaseURL + apiVersion + '/admin/gate',
    beAdminPhone: (pageSize, pageNumber, phoneFilter) => `${apiBaseURL}${apiVersion}/admin/phone/page/${pageSize}/${pageNumber}${phoneFilter ? ('/' + phoneFilter) : ''}`,
    beAdminUpdatePhone: (sid) => `${apiBaseURL}${apiVersion}/admin/phone/${sid}`
};

// Start validation interval
startValidateVersion();
global.startValidateVersion = startValidateVersion;
global.stopValidateVersion = stopValidateVersion;
global.isNewVersion = isNewVersion;

export default config;
