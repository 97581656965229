import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'primereact/button';

class ButtonI18n extends Component {
    render() {
        return (
            <FormattedMessage id={this.props.i18nId} defaultMessage={this.props.defaultMessage}>
                {formattedMessage =>
                    <Button type={this.props.type}
                        style={this.props.style}
                        icon={this.props.icon}
                        className={this.props.className}
                        label={formattedMessage}
                        onClick={this.props.onClick}
                        disabled={this.props.disabled} />
                }
            </FormattedMessage>
        );
    }
}

export default ButtonI18n;