import React, { Component } from 'react';
import ButtonI18n from '../i18n/ButtonI18n';
import { Messages } from 'primereact/messages';
import LoadingSpinner from '../LoadingSpinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TrajetsService } from '../../service/TrajetsService';
import { PasserellesService } from '../../service/PasserellesService';
import { MultiSelect } from 'primereact/multiselect';
import { dsp } from '../src/dsp.js';
import auth from '../../utils/auth';
import appUtil from '../../utils/appUtil';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Button } from 'primereact/button';
import * as XLSX from 'xlsx';

class TrackRunList extends Component {

    constructor(props) {
        super(props);
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        this.state = {
            loading: false,
            selectedGateways: null,
            selectedYears: null,
            selectedSurfaces: null,
            trackRunList: [],
            passerelles: [],
            years: [{ year: lastYear.toString() }, { year: currentYear.toString() }]
        };

        this.handleChangeGateways = this.handleChangeGateways.bind(this);
        this.handleChangeYears = this.handleChangeYears.bind(this);
        this.handleChangeSurfaces = this.handleChangeSurfaces.bind(this);
        this.loadTrackRunList = this.loadTrackRunList.bind(this);
        this.export = this.export.bind(this);
        this.calculatePathVolumeSum = this.calculatePathVolumeSum.bind(this);
        this.calculatePluvioMean = this.calculatePluvioMean.bind(this);
        this.trajetsService = new TrajetsService();
        this.passerellesService = new PasserellesService();
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.passerellesService.getAllPasserelles()
            .then((data) => {
                if (data) {
                    let p = [];
                    for (let i = 0; i < data.length; i++) {
                        p.push({ id: data[i].id, name: `${data[i].name} (SN: ${data[i].id})`, ...data[i] });
                    }
                    let trackRunsYears = sessionStorage.getItem('TrackRunsYears');
                    let years = trackRunsYears ? trackRunsYears.split(',').map(y => { return { year: y } }) : null;

                    let trackRunsGateways = sessionStorage.getItem('TrackRunsGateways');
                    let gateways = trackRunsGateways ? trackRunsGateways.split(',').map(gatewayId => {
                        let gatewayParam = p.filter(g => g.id === gatewayId);
                        if (gatewayParam && gatewayParam.length > 0) {
                            return gatewayParam[0];
                        }
                    }) : null;

                    let trackRunsSurfaces = sessionStorage.getItem('TrackRunsSurfaces');
                    let surfaces = trackRunsSurfaces ? trackRunsSurfaces.split(',').map(surfaceId => {
                        let userSurface = auth.getUserInfo().surfaces.filter(surface => surface.id === surfaceId);
                        if (userSurface && userSurface.length > 0) {
                            return userSurface[0];
                        }
                    }) : null;

                    this.setState({
                        selectedGateways: gateways,
                        selectedYears: years,
                        selectedSurfaces: surfaces,
                        passerelles: p,
                        loading: false,
                    });

                    if (gateways && years) {
                        this.loadTrackRunList(years, gateways, surfaces);
                    }
                } else {
                    this.messages.show({ severity: 'error', summary: 'ERREUR', detail: '', sticky: true });
                    this.setState({
                        loading: false,
                        passerelles: [],
                    });
                }
            }).catch((error) => {
                console.error(error);
                this.messages.show({
                    severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true
                });
                this.setState({ loading: false });
            });
    }

    loadTrackRunList(years, gateways, surfaces) {
        this.setState({ loading: true });

        let promises = [];

        for (let year of years) {
            for (let gateway of gateways) {
                let startDate = new Date(parseInt(year.year), 0, 1);
                let endDate = new Date(parseInt(year.year), 11, 31);
                promises.push(this.trajetsService.getStartPathInRange(gateway.id, startDate.getTime(), endDate.getTime()));
            }
        }
        Promise.all(promises).then((results) => {
            let data = [].concat.apply([], results);

            if (!surfaces || surfaces.length === 0) {
                data.map(run => {
                    let firstPacket = run;
                    let matchingSurfaces = !auth.getUserInfo().surfaces ? [] : auth.getUserInfo().surfaces.filter(surface => {
                        const firstPacketStartCoor = { latitude: appUtil.lat(firstPacket.startCoor.latitude), longitude: appUtil.lng(firstPacket.startCoor.longitude) };
                        const firstPacketEndCoor = { latitude: appUtil.lat(firstPacket.endCoor.latitude), longitude: appUtil.lng(firstPacket.endCoor.longitude) };
                        const surfaceStartPosition = { latitude: appUtil.lat(surface.startPosition.latitude), longitude: appUtil.lng(surface.startPosition.longitude) };
                        const surfaceEndPosition = { latitude: appUtil.lat(surface.endPosition.latitude), longitude: appUtil.lng(surface.endPosition.longitude) };

                        let score = dsp.validateTrackOverArea(firstPacketStartCoor, firstPacketEndCoor, surfaceStartPosition, surfaceEndPosition, 25);
                        return score > 0
                    });
                    firstPacket.surfaces = matchingSurfaces;
                });
            } else {
                data = data.filter(run => {
                    let firstPacket = run;
                    let matchingSurfaces = surfaces.filter(surface => {
                        const firstPacketStartCoor = { latitude: appUtil.lat(firstPacket.startCoor.latitude), longitude: appUtil.lng(firstPacket.startCoor.longitude) };
                        const firstPacketEndCoor = { latitude: appUtil.lat(firstPacket.endCoor.latitude), longitude: appUtil.lng(firstPacket.endCoor.longitude) };
                        const surfaceStartPosition = { latitude: appUtil.lat(surface.startPosition.latitude), longitude: appUtil.lng(surface.startPosition.longitude) };
                        const surfaceEndPosition = { latitude: appUtil.lat(surface.endPosition.latitude), longitude: appUtil.lng(surface.endPosition.longitude) };

                        let score = dsp.validateTrackOverArea(firstPacketStartCoor, firstPacketEndCoor, surfaceStartPosition, surfaceEndPosition, 25);
                        return score > 0
                    });
                    firstPacket.surfaces = matchingSurfaces;
                    return matchingSurfaces && matchingSurfaces.length > 0;
                });
            }
            this.setState({
                trackRunList: data,
                loading: false
            });
        }).catch(error => {
            console.error(error);
            this.messages.show({
                severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true
            });
            this.setState({ loading: false });
        });
    }

    handleChangeGateways(event) {
        sessionStorage.setItem('TrackRunsGateways', event.value.map(g => g.id).join(','));
        this.setState({
            selectedGateways: event.value
        });
    }

    handleChangeYears(event) {
        sessionStorage.setItem('TrackRunsYears', event.value.map(g => g.year).join(','));
        this.setState({
            selectedYears: event.value
        });
    }

    handleChangeSurfaces(event) {
        sessionStorage.setItem('TrackRunsSurfaces', event.value.map(g => g.id).join(','));
        this.setState({
            selectedSurfaces: event.value
        });
    }

    surfaceTemplate(rowData, column) {
        if (rowData && rowData.surfaces && rowData.surfaces.length > 0) {
            return rowData.surfaces.map(surface =>
                <Link
                    to={{
                        pathname: "/surface/editSurface/" + surface.id,
                        state: { selectedRun: surface }
                    }}
                > {surface.description}</Link >);
        } else {
            return (
                <div className="button-demo">
                    <Link to={{
                        pathname: '/surface/addSurface',
                        state: { selectedRun: rowData }
                    }}>
                        <ButtonI18n i18nId="track.surface.define" defaultMessage="Define as new surface" className="p-button-rounded  p-button-danger" />
                    </Link>
                </div>
            );
        }
    }

    startTemplate(rowData, column) {
        return new Date(parseInt(rowData.pathStartTimestamp)).toLocaleString();
    }

    endTemplate(rowData, column) {
        return new Date(parseInt(rowData.pathEndTimestamp)).toLocaleString();
    }

    durationTemplate(rowData, column) {
        let ms = parseInt(rowData.pathEndTimestamp) - parseInt(rowData.pathStartTimestamp);
        return (ms / 3600000).toFixed(1);
    }

    pathDistanceTemplate(rowData, column) {
        return parseInt(rowData.pathDistance);
    }

    gatewayNameTemplate(rowData, column) {
        for (let g of auth.getUserInfo().gateways) {
            if (g.id === rowData.serialNumber) {
                return g.name
            }
        }
        return 'No Name';
    }

    export() {
        let jsonSheet = [];
        for (let run of this.state.trackRunList) {
            jsonSheet.push({
                "SN": run.serialNumber,
                "Nom": this.gatewayNameTemplate(run),
                "Début": this.startTemplate(run),
                "Fin": this.endTemplate(run),
                "Durée (h)": this.durationTemplate(run),
                "Distance": this.pathDistanceTemplate(run),
                "Surfaces": run.surfaces.map(surface => surface.description).join(','),
                "Pluviométrie (mm)": run.pathPluvio,
                "Volume d\'eau (m³)": run.pathVolume,
            });
        }
        const pluvioMean = this.calculatePluvioMean();
        const volumeTotal = this.calculatePathVolumeSum();
        jsonSheet.push({
            "Distance": 'Totaux:',
            "Pluviométrie (mm)": pluvioMean,
            "Volume d\'eau (m³)": volumeTotal
        });

        const ws = XLSX.utils.json_to_sheet(jsonSheet);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        XLSX.writeFile(wb, 'out.xlsx');
    }

    calculatePluvioMean() {
        if (!this.state.trackRunList || this.state.trackRunList.length <= 0) {
            return 0;
        }
        return this.state.trackRunList.reduce((acc, run) => {
            // ignore the nul pluviometry in the mean calculation
            if (!run.pathPluvio) {
                return acc;
            }
            return acc + parseInt(run.pathPluvio);
        }, 0) / this.state.trackRunList.length;
    }

    calculatePathVolumeSum() {
        return this.state.trackRunList.reduce((acc, run) => {
            let pathVolume = run.pathVolume ? parseInt(run.pathVolume) : 0;
            return acc + pathVolume;
        }, 0);
    }

    render() {
        const header = (
            <div>
                <div style={{ textAlign: 'left' }}>
                    <ButtonI18n i18nId="track.refresh" defaultMessage="Refresh" className="button-auto" onClick={() => this.loadTrackRunList(this.state.selectedYears, this.state.selectedGateways, this.state.selectedSurfaces)} label="Afficher" style={{ marginRight: '5px' }} />
                    <Button type="button" icon="pi pi-external-link" label="Export" onClick={this.export}></Button>
                </div>
            </div>);
        const footer = (
            <div>
                <p>Pluviométrie moyenne: {this.calculatePluvioMean()} mm</p>
                <p>Volume d'eau Total: {this.calculatePathVolumeSum()} m³</p>
            </div>);
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <LoadingSpinner rendered={this.state.loading} i18nId="spinner.saving" />
                        <Messages ref={(el) => this.messages = el} />
                        <MultiSelect value={this.state.selectedGateways} options={this.state.passerelles} onChange={this.handleChangeGateways} optionLabel="name" placeholder="Select Gateway" style={{ marginRight: '5px' }} />
                        <MultiSelect value={this.state.selectedYears} options={this.state.years} onChange={this.handleChangeYears} placeholder="Select Year" optionLabel="year" style={{ marginRight: '5px' }} />
                        <MultiSelect value={this.state.selectedSurfaces} options={auth.getUserInfo().surfaces} onChange={this.handleChangeSurfaces} placeholder="Select Surface" optionLabel="description" />

                        <DataTable value={this.state.trackRunList} header={header} footer={footer}>
                            <Column field="serialNumber" header="SN"></Column>
                            <Column body={this.gatewayNameTemplate.bind(this)} header="Nom"></Column>
                            <Column body={this.startTemplate.bind(this)} header="Début"></Column>
                            <Column body={this.endTemplate.bind(this)} header="Fin"></Column>
                            <Column body={this.durationTemplate.bind(this)} header="Durée (h)"></Column>
                            <Column body={this.pathDistanceTemplate.bind(this)} header="Distance (m)"></Column>
                            <Column field="pathPluvio" header="Pluviométrie"></Column>
                            <Column field="pathVolume" header="Volume d'eau"></Column>
                            <Column body={this.surfaceTemplate.bind(this)} header={<FormattedMessage id="surfaces.title"
                                defaultMessage="Surfaces" />} />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(TrackRunList);