import React, { Component } from 'react';
import { EtatSys } from './EtatSys';
import { EtatsService } from '../service/EtatsService';
import StateResolver from './StatesResolver';
import logger from '../config/logger';
import config from '../config/config';
import appUtil from '../utils/appUtil';
import ButtonI18n from './i18n/ButtonI18n';
import { FormattedMessage } from 'react-intl';
import { Button } from 'primereact/button';

const EXPENDED_STATE_VIEW_LIMIT = 2;

export class EtatSystems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataEtatsValue: [],
            lastUpdate: 0,
            isMap: false,
            selectedData: 0,
        };
        this.etatsService = new EtatsService();
        this.theMap = null;
        this.markers = [];
        this.bounds = null;
    }

    centerMap() {
        let i;
        this.bounds = new window.google.maps.LatLngBounds();

        for (i = 0; i < this.markers.length; i++) {
            this.bounds.extend(this.markers[i].getPosition());
        }
        this.theMap.setCenter(this.bounds.getCenter());
        setTimeout(function () { this.theMap.fitBounds(this.bounds); }.bind(this), 1);

    }
    afficherCarte(b) {

        if (!window.google) {

            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBTwW1ZNLLockxUERSIlt4gU2OuU-6Cnj8&callback=initMap`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);

            s.addEventListener('load', e => {
                this.createMap(b);
                this.onScriptLoad(b)
                this.centerMap();
            })

        } else {

            if (this.theMap) {
                this.onScriptLoad(b);
                this.centerMap();
            }
            else {
                this.createMap(b);
                this.onScriptLoad(b)
                this.centerMap();
            }
        }
        this.setState({ isMap: true });
        this.setState({ selectedData: b });
    }
    createMap(data) {
        if (data) {
            this.theMap = new window.google.maps.Map(
                document.getElementById("mapId"), {
                options: {
                    center: { lat: appUtil.lat(data.latitude), lng: appUtil.lng(data.longitude) },
                    streetViewControl: false, maxZoom: 20
                }, mapTypeId: window.google.maps.MapTypeId.SATELLITE

            });
        }
    }
    createGreenMarker(lat, lng) {
        var iconGreen = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'green',
            fillOpacity: 1,
            strokeColor: 'green',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };
        if (lat && lng) {
            let greenMarker = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(lat),
                    lng: appUtil.lng(lng),
                },
                map: this.theMap,
                icon: iconGreen
            });
            this.markers.push(greenMarker);

        }
    }

    createYellowMarker(lat, lng) {
        var iconYellow = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'yellow',
            fillOpacity: 1,
            strokeColor: 'yellow',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };
        if (lat && lng) {
            let yellowMarker = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(lat),
                    lng: appUtil.lng(lng),
                },
                map: this.theMap,
                icon: iconYellow
            });
            this.markers.push(yellowMarker);
        }

    }

    createRedMarker(lat, lng) {
        var iconYellow = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'red',
            fillOpacity: 1,
            strokeColor: 'red',
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7
        };
        if (lat && lng) {
            let yellowMarker = new window.google.maps.Marker({
                position: {
                    lat: appUtil.lat(lat),
                    lng: appUtil.lng(lng),
                },
                map: this.theMap,
                icon: iconYellow
            });
            this.markers.push(yellowMarker);
        }

    }

    createFlagMarker(lat, lng) {
        var iconFlag = config.iconBase + 'finish-flag.png';
        let flagMarker = new window.google.maps.Marker({
            position: {
                lat: appUtil.lat(lat),
                lng: appUtil.lng(lng),
            },
            map: this.theMap,
            icon: iconFlag
        });
        this.markers.push(flagMarker);
    }

    onScriptLoad(b) {
        while (this.markers.length) {
            this.markers.pop().setMap(null);
        }
        switch (b.stateInt) {
            case 0:
                // idle
                this.createGreenMarker(b.latitude, b.longitude);
                break;
            case 1:
                // Waiting GPS
                this.createGreenMarker(b.latitude, b.longitude);
                break;
            case 2:
                // Ready to start               
                this.createRedMarker(b.latitude, b.longitude);
                this.createFlagMarker(b.endCoor.latitude, b.endCoor.longitude);
                break;
            case 3:
                // Running            
                this.createGreenMarker(b.startCoor.latitude, b.startCoor.longitude);
                this.createRedMarker(b.latitude, b.longitude);
                this.createFlagMarker(b.endCoor.latitude, b.endCoor.longitude);
                break;
            case 4:
                // Close to end                
                this.createGreenMarker(b.startCoor.latitude, b.startCoor.longitude);
                this.createRedMarker(b.latitude, b.longitude);
                this.createFlagMarker(b.endCoor.latitude, b.endCoor.longitude);
                break;
            default:
                // N/A
                this.createGreenMarker(b.latitude, b.longitude);
                break;
        }

    }

    returnToList() {
        this.setState({ isMap: false });
        this.setState({ selectedData: 0 });
    }

    refresh() {
        this.etatsService.getAllEtat()
            .then((data) => {
                let uiData = [];
                for (let i = 0; i < data.length; i++) {

                    uiData.push(this.buildUiData(data[i]));

                    if (this.state.selectedData.id === uiData[i].id) {
                        this.setState({ selectedData: uiData[i] })
                    }
                }
                uiData.sort((a, b) => {
                    let comparison = 0;
                    if (a.name > b.name) {
                        comparison = 1;
                    } else if (a.name < b.name) {
                        comparison = -1;
                    }
                    return comparison;
                });
                this.setState({ dataEtatsValue: uiData, lastUpdate: new Date().toLocaleTimeString() })
                this.onScriptLoad(this.state.selectedData);
                this.centerMap();
            }).catch((error) => {
                logger.error(error);
            });

    }

    getAllStates() {
        this.etatsService.getAllEtat()
            .then((data) => {
                let uiData = [];
                for (let i = 0; i < data.length; i++) {
                    let gatewayInfo = data[i];
                    uiData.push(this.buildUiData(gatewayInfo));
                }
                uiData.sort((a, b) => {
                    let comparison = 0;
                    if (a.name > b.name) {
                        comparison = 1;
                    } else if (a.name < b.name) {
                        comparison = -1;
                    }
                    return comparison;
                });
                this.setState({ dataEtatsValue: uiData, lastUpdate: new Date().toLocaleTimeString() })
            }).catch((error) => {
                logger.error(error);
            });
    }

    buildUiData(gatewayInfo) {
        return {
            id: gatewayInfo.id,
            name: gatewayInfo.name,
            date: new Date(parseInt(gatewayInfo.timestamp)).toLocaleDateString(),
            heure: new Date(parseInt(gatewayInfo.timestamp)).toLocaleTimeString(),
            etat: <StateResolver state={gatewayInfo.state} />,
            pression: gatewayInfo.pressure,
            distance: gatewayInfo.endPointDistance,
            mouvement: gatewayInfo.lastMoveDelay,
            batterie: gatewayInfo.batteryLevel / 100,
            temp: gatewayInfo.temperature,
            buse: gatewayInfo.nozzle,
            latitude: gatewayInfo.latitude,
            longitude: gatewayInfo.longitude,
            startCoor: gatewayInfo.startCoor,
            endCoor: gatewayInfo.endCoor,
            stateInt: gatewayInfo.state,
            speed: gatewayInfo.speed,
            firmware: gatewayInfo.firmware,
            buttonStateIndicator: this.evaluateButtonStateIndicator(gatewayInfo),
            alert: gatewayInfo.alertMask
        };
    }

    evaluateButtonStateIndicator(gatewayInfo) {

        if (gatewayInfo.alertMask > 0) {
            return 'p-button-danger';
        } else if (gatewayInfo.state === 0) {
            return 'p-button-secondary';
        } else if (gatewayInfo.state === 2) {
            return 'p-button-success';
        } else if (gatewayInfo.state === 3) {
            return 'p-button-info';
        } else if (gatewayInfo.state === 4) {
            return 'p-button-warning';
        } else {
            return 'p-button-secondary';
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshStatesInterval);
    }

    componentDidMount() {
        this.getAllStates();
        this.refreshStatesInterval = setInterval(() => {
            this.getAllStates();
        }, 60000);
    }

    handleRefresh() {
        this.getAllStates();
    }

    render() {
        return (
            <div>
                <p>
                    <FormattedMessage id="state.lastUpdate" /> {this.state.lastUpdate}
                </p>
                {
                    this.state.dataEtatsValue.map(data =>
                        (this.state.selectedData === 0 || this.state.selectedData.id === data.id) &&
                        <div key={data.id}>
                            <div className="card" style={this.state.isMap || this.state.dataEtatsValue.length <= EXPENDED_STATE_VIEW_LIMIT ? { display: 'none' } : {}}>
                                <h1>
                                    <FormattedMessage id="state.ofSN" defaultMessage="State of {name} (SN:{sn}, V{firmware})" values={{
                                        name: data.name,
                                        sn: data.id,
                                        firmware: data.firmware ? data.firmware.toString() : "0000"
                                    }} />
                                </h1>
                                <Button label={data.etat}
                                    style={{ marginRight: '5px' }}
                                    onClick={() => this.afficherCarte(data)}
                                    className={'p-button-raised p-button-rounded button-auto ' + data.buttonStateIndicator} />
                                {data.date},&nbsp;{data.heure}
                            </div>
                            <div className="button-demo" style={this.state.selectedData !== 0 ? {} : { display: 'none' }}>
                                <ButtonI18n i18nId="state.back" defaultMessage="Back"
                                    onClick={() => this.returnToList()}
                                    className="p-button-rounded  p-button-success" />
                                <ButtonI18n i18nId="state.refresh" defaultMessage="Refresh"
                                    onClick={() => this.refresh(data)}
                                    className="p-button-rounded" />
                            </div>
                            <div style={this.state.isMap || this.state.dataEtatsValue.length <= EXPENDED_STATE_VIEW_LIMIT ? {} : { display: 'none' }}>
                                <ButtonI18n i18nId="state.map" defaultMessage="Map"
                                    style={this.state.selectedData === 0 ? {} : { display: 'none' }}
                                    onClick={() => this.afficherCarte(data)}
                                    className={'p-button-raised p-button-rounded button-auto ' + data.buttonStateIndicator} />
                                <EtatSys key={data.id} isMap={false} dataEtatsValue={data} />
                            </div>
                        </div>
                    )
                }
                <div style={this.state.isMap ? {} : { display: 'none' }} className="p-col-12 p-lg-12 ">
                    <h3>
                        <FormattedMessage id="state.selectedTrack" defaultMessage="Map of the selected track" />
                    </h3>
                    <div className="card p-col">
                        <div id="mapId" style={{ width: '100%', height: '500px' }}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}