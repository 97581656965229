import config from '../config/config';
import BaseServiceClient from './BaseServiceClient';

export class EtatsService {

    getEtat() {
        return BaseServiceClient.get(config.beStateURL);
    }

    getAllEtat() {
        return BaseServiceClient.get(config.beStatesURL);
    }

}