import React, { Component } from 'react';
import config from '../config/config';

export class VersionsView extends Component {

    render() {
        return (
            <div>
                <textarea>{JSON.stringify(config, null, 4)}</textarea>
            </div>
        );
    }
}