import axios from 'axios';
import logger from '../config/logger';
import auth from '../utils/auth';

axios.defaults.headers.common['Authorization'] = localStorage.jwtToken;

function manageResponse(res, resolve, reject) {
    return resolve(res.data);
}

function manageError(error, reject) {
    logger.error(error);
    if (error.response && error.response.status === 403) {
        auth.logout();
    }
    return reject(error);
}

export default {
    get(endpoint) {
        return new Promise((resolve, reject) => {
            axios.get(endpoint)
            .then(res => manageResponse(res, resolve, reject))
            .catch(error => manageError(error, reject));
        });
    },
    post(endpoint, body) {
        return new Promise((resolve, reject) => {
            axios.post(endpoint, body)
            .then(res => manageResponse(res, resolve, reject))
            .catch((error) => manageError(error, reject));
        });
    },
    delete(endpoint, body) {
        return new Promise((resolve, reject) => {
            axios.delete(endpoint, body)
            .then(res => manageResponse(res, resolve, reject))
            .catch((error) => manageError(error, reject));
        });
    }
}