import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import ButtonI18n from '../i18n/ButtonI18n';
import { Messages } from 'primereact/messages';
import LoadingSpinner from '../LoadingSpinner';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import UserService from '../../service/UserService';
import GoogleMaps from '../map/GoogleMaps';

let messages;
const userService = new UserService();
const mapObject = new GoogleMaps();

export const AddSurface = (props) => {
    const history = useHistory();

    let startingPoint = '';
    if (props && props.location && props.location.state && props.location.state.selectedRun && props.location.state.selectedRun.startCoor) {
        startingPoint = props.location.state.selectedRun.startCoor.latitude + ',' + props.location.state.selectedRun.startCoor.longitude;
    } else {
        history.push('/surfaces');
        return <div>Redirecting</div>;
    }

    let endingPoint = '';
    if (props && props.location && props.location.state && props.location.state.selectedRun && props.location.state.selectedRun.endCoor) {
        endingPoint = props.location.state.selectedRun.endCoor.latitude + ',' + props.location.state.selectedRun.endCoor.longitude;
    } else {
        history.push('/surfaces');
        return <div>Redirecting</div>;
    }

    const id = props.location.state.selectedRun.serialNumber + '-' + props.location.state.selectedRun.timestamp;
    const [description, setDescription] = useState('');
    const [fieldNumber, setFieldNumber] = useState('');
    const [surfaceNumber, setSurfaceNumber] = useState('');
    const [startPosition, setStartPosition] = useState(startingPoint);
    const [endPosition, setEndPosition] = useState(endingPoint);
    const [surfaceWidth, setSurfaceWidth] = useState('50');
    const [saving, setSaving] = useState(false);

    const onDescriptionChange = e => setDescription(e.target.value);
    const onFieldNumberChange = e => setFieldNumber(e.target.value);
    const onSurfaceNumberChange = e => setSurfaceNumber(e.target.value);
    const onStartPositionChange = e => setStartPosition(e.target.value);
    const onEndPositionChange = e => setEndPosition(e.target.value);
    const onSurfaceWidthChange = e => setSurfaceWidth(e.target.value);

    const loadMap = () => {
        console.log("loadMap - called");
        let slat = props.location.state.selectedRun.startCoor.latitude;
        let slng = props.location.state.selectedRun.startCoor.longitude;
        let elat = props.location.state.selectedRun.endCoor.latitude;
        let elng = props.location.state.selectedRun.endCoor.longitude;

        mapObject.afficherCarte({
            latitude: slat,
            longitude: slng,
            endCoor: {
                latitude: elat,
                longitude: elng
            },
            stateInt: 2
        });
        mapObject.createPolyline([
            {
                lat: slat,
                lng: slng
            },
            {
                lat: elat,
                lng: elng
            }
        ]);

    };

    useEffect(() => {
        loadMap();
        return () => { };
    }, []);

    const onSubmit = e => {
        e.preventDefault();
        setSaving(true);

        userService.getCurrentUser()
            .then(user => {
                const newSurface = {
                    id: id,
                    description: description,
                    fieldNumber: fieldNumber,
                    surfaceNumber: surfaceNumber,
                    startPosition: props.location.state.selectedRun.startCoor,
                    endPosition: props.location.state.selectedRun.endCoor,
                    surfaceWidth: surfaceWidth
                };
                if (user.surfaces) {
                    user.surfaces.push(newSurface);
                } else {
                    user.surfaces = [newSurface];
                }
                return userService.save(user);
            }).then(savedUser => {
                console.log(savedUser);
                messages.clear();
                messages.show({ severity: 'info', summary: 'Success', detail: description + ' saved', sticky: true });
                setSaving(false);
                history.push('/surfaces');
            }).catch(error => {
                messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
                setSaving(false);
            });
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <LoadingSpinner rendered={saving} i18nId="spinner.saving" />
                    <Messages ref={(el) => messages = el} />
                    <div>
                        <p className="p-float-label">
                            <InputText id="surfaceId" value={id} disabled />
                            <label htmlFor="surfaceId"><FormattedMessage id="surface.id" defaultMessage="ID" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="description" value={description} onChange={onDescriptionChange} />
                            <label htmlFor="description"><FormattedMessage id="surface.description" defaultMessage="Description" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="fieldNumber" value={fieldNumber} onChange={onFieldNumberChange} />
                            <label htmlFor="fieldNumber"><FormattedMessage id="surface.fieldNumber" defaultMessage="Field Number" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="surfaceNumber" value={surfaceNumber} onChange={onSurfaceNumberChange} />
                            <label htmlFor="surfaceNumber"><FormattedMessage id="surface.surfaceNumber" defaultMessage="Surface Number" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="startPosition" value={startPosition} onChange={onStartPositionChange} disabled />
                            <label htmlFor="startPosition"><FormattedMessage id="surface.startPosition" defaultMessage="Start Position" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="endPosition" value={endPosition} onChange={onEndPositionChange} disabled />
                            <label htmlFor="endPosition"><FormattedMessage id="surface.endPosition" defaultMessage="End Position" /></label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="surfaceWidth" value={surfaceWidth} onChange={onSurfaceWidthChange} />
                            <label htmlFor="surfaceWidth"><FormattedMessage id="surface.surfaceWidth" defaultMessage="Surface Width (m)" /></label>
                        </p>
                        <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={onSubmit} />
                    </div>
                </div>
                <div className="card p-col">
                    <div id="mapId" style={{ width: '100%', height: '500px' }}>
                    </div>
                </div>
            </div>
        </div>
    );
};