import BaseServiceClient from './BaseServiceClient';
import config from '../config/config';

class NozzleService {

  getNozzleFabList() {
    return BaseServiceClient.get(config.beNozzleFabURL);
  }

  saveNozzleFabList(fabList) {
    return BaseServiceClient.post(config.beNozzleFabURL, fabList);
  }

  saveCustomNozzle(customNozzle) {
    return BaseServiceClient.post(config.beCustomNozzleURL, customNozzle);
  }
}

export default NozzleService;