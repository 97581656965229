import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import ButtonI18n from './i18n/ButtonI18n';
import UserService from '../service/UserService';
import logger from '../config/logger';
import { Messages } from 'primereact/messages';
import { InputMask } from 'primereact/inputmask';
import CheckoutService from '../service/CheckoutService';
import { injectStripe } from 'react-stripe-elements';
import { InputTextarea } from 'primereact/inputtextarea';

class User extends Component {

    constructor(props) {
        console.log(props);
        super(props);
        this.state = {
            user: {},
            userJson: ''
        };
        this.save = this.save.bind(this);
        this.checkout = this.checkout.bind(this);
        this.delete = this.delete.bind(this);
        this.saveUserJson = this.saveUserJson.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.userService = new UserService();
        this.checkoutService = new CheckoutService();
    }

    save() {
        this.saveUser(this.state.user);
    }

    saveUserJson() {
        let user = this.state.user;
        try {
            user = JSON.parse(this.state.userJson);
            var confirmed = window.confirm("Please Make Triple Validation Before Saving User as Json. Are you sure?");
            if (!confirmed) {
                return;
            }
            this.saveUser(user);
        } catch (error) {
            console.error(error);
            this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
        }
    }

    saveUser(user) {
        this.userService.save(user)
        .then((data) => {
            logger.log(data);
            if (data) {
                this.messages.show({ severity: 'info', summary: 'Success', detail: this.state.user.username + ' user saved', sticky: true });
            } else {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: 'NO DATA', sticky: true });
            }
            this.setState({
                user: data,
                userJson: JSON.stringify(data, null, 2)
            });
        }).catch((error) => {
            console.error(error);
            this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
        });
    }

    checkout() {
        this.checkoutService.checkout(this.state.user.username)
            .then((session) => {
                const sid = session.id
                this.props.stripe.redirectToCheckout({ sessionId: sid })
                    .then(function (errorResult) {
                        alert(errorResult.error.message);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    delete() {
        this.userService.delete(this.state.user)
            .then((data) => {
                logger.log(data);
                if (data) {
                    this.messages.show({ severity: 'info', summary: 'Success', detail: this.state.user.username + ' user deleted', sticky: true });
                } else {
                    this.messages.show({ severity: 'error', summary: 'ERREUR', detail: 'NO DATA', sticky: true });
                }
            }).catch((error) => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    componentDidMount() {
        let username = this.props.username;
        if (!username) {
            username = 'admin';
        }
        this.userService.get(username)
            .then((result) => {
                this.setState({
                    user: result,
                    userJson: JSON.stringify(result, null, 2)
                });
            })
            .catch((error) => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
            });
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Messages ref={(el) => this.messages = el} />
                        <h1>User {this.state.user.username}</h1>
                        {this.state.user.delete ? <h2>DELETED</h2> : ''}
                        <p className="p-float-label">
                            <InputText id="password" value={this.state.user.password} onChange={(e) => { let u = { ...this.state.user, password: e.target.value }; this.setState({ user: u }); }} />
                            <label htmlFor="password">Password</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="firstname" value={this.state.user.firstname} onChange={(e) => { let u = { ...this.state.user, firstname: e.target.value }; this.setState({ user: u }); }} />
                            <label htmlFor="firstname">First Name</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="lastname" value={this.state.user.lastname} onChange={(e) => { let u = { ...this.state.user, lastname: e.target.value }; this.setState({ user: u }); }} />
                            <label htmlFor="lastname">Last Name</label>
                        </p>
                        <p className="p-float-label">
                            <InputText id="email" value={this.state.user.email} onChange={(e) => { let u = { ...this.state.user, email: e.target.value }; this.setState({ user: u }); }} />
                            <label htmlFor="email">Email</label>
                        </p>
                        <p className="p-float-label">
                            <InputMask id="phone" mask="999-999-9999" value={this.state.user.phone} onChange={(e) => { let u = { ...this.state.user, phone: e.target.value }; this.setState({ user: u }); }} />
                            <label htmlFor="phone">Phone Number</label>
                        </p>
                        <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={this.save} />

                        <h1>Payment</h1>
                        <ButtonI18n i18nId="commons.payment.proceed" defaultMessage="Proceed with payment" icon="pi pi-cart" onClick={this.checkout} />
                    </div>
                    <div className="card">
                        <InputTextarea value={this.state.userJson} onChange={(e) => this.setState({ userJson: e.target.value })} rows={5} cols={30} />
                        <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={this.saveUserJson} />
                    </div>
                </div>
            </div>
        );
    }
}

export default injectStripe(User);