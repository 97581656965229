import { charte } from '../src/charte.js'
import { dsp } from '../src/dsp.js'
import { create, all } from 'mathjs';

const config = { };
const math = create(all, config);

// Sampling period is every minute, timebase is measured in hours
const Tsamp = 1/60;

export default class pluvio {
    
    constructor(rawdata, rawchart, mode) {

        // fixed 180 degree water angle assumption for now
        this.waterAngleRad = Math.PI;

        // default mode = 0, all filters activated
        if (!mode) {
            this.mode = 0;
        }
        else {
            this.mode = mode;
        }

        // calculate chart polynomials from raw chart data
        this.irrigation_chart = new charte(rawchart);

        // assume same nozzle used on entire trajectory and we index from 0
        this.nozzle = rawdata[0].nozzle-1;

        // retrieve basic vectors from raw data
        this.speed = [];
        this.tickCount = [];
        this.pressure = [];
        
        this.endPointDistance = [];
        this.date = [];
        this.timeStamp = [];

        // for time vector calculations
        var prevEntry = rawdata[rawdata.length-1];
        this.startDate = dsp.dateFromGPS(rawdata[0].utc, rawdata[0].date);
 
        // for distance vector calculations
        this.startCoor = dsp.latLongFromGPS(rawdata[0].startCoor)
		
		this.endCoor = dsp.latLongFromGPS( {"latitude":rawdata[rawdata.length-1].latitude,"longitude":rawdata[rawdata.length-1].longitude} );
		this.endCoor_AsSetByUser = dsp.latLongFromGPS(rawdata[rawdata.length-1].endCoor);
		this.remDistance = dsp.distanceLatLong(this.endCoor, this.endCoor_AsSetByUser);
		
        var pathlength = dsp.distanceLatLong(this.endCoor, this.startCoor);
		
		/////////////////////////////////////////////////////////////////////////
		// FIX1: make sure tick count does not reset during travel
		// ex: unexpected reset of Gateway causing tick count=0
		var last_tick = rawdata[0].tickCount;
		var tick_offset = 0;
		
		// Uncomment to debug
		//console.log({rawdata:rawdata});
		
		for(let i=1; i<rawdata.length; i++)
		{
			rawdata[i].tickCount += tick_offset;
			
			if( rawdata[i].tickCount < rawdata[i-1].tickCount )
			{
				let new_offset = rawdata[i-1].tickCount;
				rawdata[i].tickCount += (new_offset - tick_offset);
				tick_offset = new_offset;
				console.log("tickCount offset added at pt "+ i + ", offset:"+ new_offset);
			}
		}
		/////////////////////////////////////////////////////////////////////////
		
		
        // Debug for comparison with Gateway method of calculating distance (can be off by several metres depending on latitude!)
        //var pathlength = dsp.distanceLatLongGateway(this.endCoor, this.startCoor);

        var endTick = rawdata[rawdata.length-1].tickCount;
        var startTick = rawdata[0].tickCount;

        // this corresponds to wheel circumference
        this.tickCalib = pathlength / (endTick - startTick);
        console.log("metres per tick = " + this.tickCalib + "m");

        // parse the data
        for (let entry of rawdata)
        {

            if (entry.utc == prevEntry.utc) {
                // sometimes we have multiple packets with identical times! Noticed the "unused" flag was changed
                console.log("Duplicate time entry! at utc = " + entry.utc);
            }
 
            // basic as-is data
            this.tickCount.push(entry.tickCount);
            this.pressure.push(entry.pressure);
            this.speed.push(entry.speed);

            // adjust time data
            let curDate = dsp.dateFromGPS(entry.utc, entry.date);

            // fix GPS time/date non-atomic roll-over bug
            let prevDate = dsp.dateFromGPS(prevEntry.utc, prevEntry.date);
            if ((curDate.getUTCDate() != prevDate.getUTCDate()) && (curDate.getUTCHours() == 23)) {
                curDate.setUTCDate(prevDate.getUTCDate());
            }
            this.date.push(curDate);

            let calcStamp = (entry.timestamp - rawdata[0].timestamp)/ 3600000;
            this.timeStamp.push(calcStamp);

            // adjusted distance data (note that ticks are quantized)
            this.endPointDistance.push(this.tickCalib * (endTick - entry.tickCount));
            prevEntry = entry;
        }

        // filtering levels based on mode
		/*
        if (this.mode <= 2) {
            // remove redundant data where position does not change
            this.removeRedundantPositions();
        }
		*/

        if (this.mode <= 1) {
            // resample to exact one-minute intervals
            this.resampleTimeData();
        }

        if (this.mode <= 0) {
            // smooth the positon data with a FIR filter and calculate speed derivative
            this.filterLowPass();
        }
    }

    // we only keep one data per quantized position
    removeRedundantPositions() {
		
		/* MT: 2020-07-01
		   WARNING: Do not use this function as it it buggy! */
		   
        var idx = dsp.find_inflexion_points(this.timeStamp, this.tickCount, 10*Tsamp);
        this.tickCount = dsp.keep_elements(this.tickCount, idx);

        this.endPointDistance = dsp.keep_elements(this.endPointDistance, idx);

        this.speed = dsp.keep_elements(this.speed, idx);
        //this.speed = dsp.keep_elements_withzero(this.speed, idx, this.timeStamp, 15*Tsamp);

        this.pressure = dsp.keep_elements(this.pressure, idx);
        //this.pressure = dsp.keep_elements_withzero(this.pressure, idx, this.timeStamp, 15*Tsamp);

        this.date = dsp.keep_elements(this.date, idx);
        this.timeStamp = dsp.keep_elements(this.timeStamp, idx);
    }

    /* This function interpolates the data on fixed timebase of 1Hz
       This is a pre-requisite to be able to perform DSP filtering
    */
    resampleTimeData() {

        // interpolate data to fixed discrete times spacing of 1 minute
        const start = this.timeStamp[0]
        const end = this.timeStamp[this.timeStamp.length-1];
        const n = Math.trunc((end - start)/Tsamp);
        var timeStamp_i = dsp.linspace(start, end, n);
        this.endPointDistance = dsp.interp1(this.timeStamp, this.endPointDistance, timeStamp_i);

        // use sticky interpolation mode to prevent speed from going slightly negative (default is to extrapolate)
        this.speed = dsp.interp1(this.timeStamp, this.speed, timeStamp_i, "sticky");
        this.pressure = dsp.interp1(this.timeStamp, this.pressure, timeStamp_i);

        // use the interpolated timestamp to recalculate the date values
        this.date = [];
        for (let stamp_h of timeStamp_i) {
            let curDate = new Date(this.startDate.getTime() + Math.round(stamp_h*3600000))
            this.date.push(curDate);
        }
        this.timeStamp = timeStamp_i;
    }

    filterLowPass() {
        // N minute averaging filter kernel (averages over N samples so N minutes)
        const N = 10;
        var havg = [];
        for (let i=0; i<N; i++) {
            havg.push(1/N);
        }

        this.endPointDistance = dsp.conv(havg, this.endPointDistance, "same_b");
        this.pressure = dsp.conv(havg, this.pressure, "same_b");
        this.speed = dsp.conv(havg, this.speed, "same_b");

        // simple central difference differentiator to compute speed
        var hdiff = [-0.5, 0, 0.5];
        for (let i=0; i<hdiff.length; i++) {
            hdiff[i] = hdiff[i]/Tsamp;
        }
        this.calcSpeed  = dsp.conv(hdiff, this.endPointDistance, "same_b");
        
    }

    // get rid of data with no movement
    remove_stopped_data(distance, pressure, timeStamp) {
		
		/* MT: 2020-07-01
		   WARNING: Do not use this function as it it buggy! */

        /* init */
        var curTimestamp = timeStamp[0];
        var deltaT = timeStamp[1] - timeStamp[0];

        var b_distance = [distance[0]];
        var b_pressure = [pressure[0]];
        var b_timeStamp = [curTimestamp];


        /* basically keep moving data and regenerate local timeStamp with proper interval */
        for (let i=1; i<distance.length; i++) {
            if ( distance[i] != b_distance[i-1]) {

                curTimestamp += deltaT;
                b_distance.push(distance[i]);
                b_pressure.push(pressure[i]);
                b_timeStamp.push(curTimestamp);
            }
        }
        return {distance: b_distance, pressure: b_pressure, timeStamp: b_timeStamp};
    }
	
	// get avg pressure value for given period of time
	get_avg_pressure(timestamp1, timestamp2) {
		var avg_pres = 0;
		var n = 0;
		var raw_pressure = this.pressure;
		var raw_timestamp = this.timeStamp;
		
		for(let k=0; k<raw_pressure.length; k++)
		{
			if( raw_timestamp[k] > timestamp1 )
			{
				if( raw_timestamp[k] < timestamp2 )
				{
					avg_pres +=  raw_pressure[k];
					n++;
				}
			}
		}
		
		avg_pres /= n;

		return avg_pres;
	}

    computeDose() {
        /* pick one point at each integer position (every metre) in the position data */
        var distance_q = this.endPointDistance.map(val => Math.round(val));
        var idx = dsp.find_changing_points(distance_q);
        distance_q = dsp.keep_elements(distance_q, idx);

        /* mark actual start and end positions for graph annotations */
        var endPos = distance_q[distance_q.length-1];
        var startPos = distance_q[0];
 
        /* normalize the lengths of all the vectors for resampling */
        var distance_i = dsp.keep_elements(this.endPointDistance, idx);
        var timeStamp_i = dsp.keep_elements(this.timeStamp, idx);
        var pressure_i = dsp.keep_elements(this.pressure, idx);
		var speed_i = dsp.keep_elements(this.speed, idx);
		
		/////////////////////////////////////////////////////////////////////
		// MT - DEBUG: Uncomment to debug!
		//console.log({idx:idx});
		//console.log("idx_len "+ idx.length);
		//console.log({distance_i:distance_i});
		//console.log({timeStamp_i:timeStamp_i});
		//console.log({pressure_i:pressure_i});
		//var pressure_raw = this.pressure; // contains "pressure" of all recorded points
		//console.log({pressure_raw:pressure_raw});
		//var timestamp_raw = this.timeStamp; // contains "timestamp" of all recorded points
		//console.log({timestamp_raw:timestamp_raw});
		/////////////////////////////////////////////////////////////////////

        /* resample them */
        timeStamp_i = dsp.interp1(distance_i, timeStamp_i, distance_q);
        pressure_i = dsp.interp1(distance_i, pressure_i, distance_q);
		speed_i = dsp.interp1(distance_i, speed_i, distance_q);
        distance_i = distance_q;

        /* ok here we have a problem when > 1m is covered per sample, we missed some points when quantizing */
        let n = distance_q[0] - distance_q[distance_q.length-1] + 1;

        /* resample again on new distance vector if necessary*/
        if (n > distance_q.length) {
            distance_i = dsp.linspace(distance_q[0], distance_q[distance_q.length-1], n);
            timeStamp_i = dsp.interp1(distance_q, timeStamp_i, distance_i);
            pressure_i = dsp.interp1(distance_q, pressure_i, distance_i);
			speed_i = dsp.interp1(distance_q, speed_i, distance_i);
        }

		////////////////////////////////////////////////////////////////////////////
		// WARNING: THIS SECTION IS BUGGY!
		
        /* now get rid of points where speed and pressure are zero */
        //var data = this.remove_stopped_data(distance_i, pressure_i, timeStamp_i);
	    //distance_i = data.distance;
	    //pressure_i = data.pressure;
        //timeStamp_i = data.timeStamp;
		
		////////////////////////////////////////////////////////////////////////////

        /* calculate other vectors from loaded chart */
        var debit_i = this.irrigation_chart.computeDebit(this.nozzle, pressure_i);
        var jetLength_i = this.irrigation_chart.computeJetLength(this.nozzle, pressure_i);

        /* compute pluviometry assuming water area is distributed on 1m wide strips of 2xjetReach m length */
        var N = Math.trunc(jetLength_i[0]);
        var jetReach = [Math.sin(this.waterAngleRad/2)*jetLength_i[0]];
        var pluvio = [0];

		
		var vol = 0; // VOL - this is the sum of all volumes
		var volume = [0]; // VOL - this is the vector
		var avgDose = 0;
		var avgPressure = 0;
		var avgSpeed = 0;
		
        for (let j=0, i=1; i<distance_i.length; i++, j++)
        {
			// Show in console in case we forget to add "0" psi point in chart
			if (debit_i[i] < 0)
			{
				// Make sure chart includes a point with pressure=0; (0,0,0)
				console.log("debit < 0: " + debit_i[i]);
				debit_i[i] = 0;
			}
			
            let dt = timeStamp_i[i]-timeStamp_i[j];                     // time differential        => h
			
			//////////////////////////////////////////////////////////////////////////////////////////
			// PATCH: remove points where time differential is too high;
			// This is to prevent multipling the "debit" by a very large value, causing 
			// a wrong pluvio calculation.
			//
			// The GOOD fix is to compute the volume with a "time" based table, instead of with a "position" based table...
			//
			
			let avg_debit = debit_i[i];
			let avg_pres = this.get_avg_pressure(timeStamp_i[j], timeStamp_i[i]);
			
			if (dt > 0.2) // variation more than 12 minutes ?
			{
				// Compute corresponding debit
				//let avg_debit2 = this.irrigation_chart.computeDebit(this.nozzle, avg_pres);
				//avg_debit = this
				
				// remove point if pressure is too low
				if ( avg_pres < 10 )
				{
					console.log("pluvio point fixed at index " + j);
					//console.log("avg pressure " + avg_pres);
					//console.log("avg debit " + avg_debit);
					dt = 0;
				}
			}
			//////////////////////////////////////////////////////////////////////////////////////////
			
            //let dv = debit_i[i] * dt;                                   // volume differential      => m3/h * h = m3
			let dv = avg_debit * dt;
            let dr = Math.sin(this.waterAngleRad/2)*jetLength_i[i];     // jet reach differential
            let da = 2*dr;

            /* this is basic pluviometry per metre uncompensated for watering pie-shaped pattern */
            pluvio.push(1000*dv/da);
			
			vol = vol+dv; // VOL
			volume.push(vol); // VOL
			
			/* Sum values for averaging - This provides averages when cart is moving only */
			avgDose += (1000*dv/da);
			avgPressure += pressure_i[j];
			avgSpeed += speed_i[j];

            /* this is the jet reach perpendicular to direction of travel */ 
            jetReach.push(dr);

            /* this is by how much we need to extend distance to calculate pluviometry past start position */
            if (N < Math.trunc(jetLength_i[i])) {
                N = Math.trunc(jetLength_i[i]);
            }
        }
		
		/* VOL - remove decimals */
		vol = dsp.truncateDecimals(vol, 0);
		
		/* Compute averages */
		avgDose /= distance_i.length;
		avgDose = dsp.truncateDecimals(avgDose, 1);
		
		avgPressure /= distance_i.length;
		avgPressure = dsp.truncateDecimals(avgPressure, 1);

		avgSpeed /= distance_i.length;
		avgSpeed = dsp.truncateDecimals(avgSpeed, 1);
 
        /* extend distance vector */
        var extDistance = [];
        for (let j=distance_i.length-2+N, i=0; i<N; i++, j--) {
            extDistance.push(j);
        }
        distance_i = extDistance.concat(distance_i);	

        /* create a pie shaped area (watering pattern with jet oscillating through an angle)
        by convoluting a normalized version of this through the pluvio data, we reproduce
        the watering pattern 
        */
		
        var xPattern = dsp.linspace(0, 1, N+1);                           
        var yPattern = dsp.h2oPattern(this.waterAngleRad, xPattern, 1);
        var hPattern = dsp.normalize(yPattern.reverse());

        var mm = dsp.conv(hPattern, pluvio);

        /* extend the basic pluvio vector to same scale */
        pluvio = math.concat(dsp.vectOfVal(NaN, N), pluvio);

        /* extend jetReach vector */
        jetReach = dsp.conv(jetReach, hPattern, "same_a");
        var jetReachPad = dsp.vectOfVal(jetReach[jetReach.length-1],hPattern.length);
        jetReach = math.concat(jetReach, jetReachPad);
		
		
		// DEBUG - UNCOMMENT for debugging!
		//console.log({startPos:startPos, endPos:endPos, pluvio:pluvio, endPointDistance: distance_i, jetReach: jetReach, mm: mm, debit_i:debit_i, timeStamp_i:timeStamp_i, volume:volume, vol,}); // MT
		
		return {startPos:startPos, endPos:endPos, pluvio:pluvio, endPointDistance: distance_i, jetReach: jetReach, mm:mm, volume:volume, vol, avgDose, avgPressure, avgSpeed};

    }

    /* this function is purely for debug of Gateway distance formula => it will be removed in final version */
    compareDistanceMethods() {
        var pathlength = dsp.distanceLatLong(this.endCoor, this.startCoor);
        var pathlengthGateway = dsp.distanceLatLongGateway(this.endCoor, this.startCoor);
        console.log("lat1=" + this.startCoor.latitude + " long1=" + this.startCoor.longitude);
        console.log("lat2=" + this.endCoor.latitude + " long2=" + this.endCoor.longitude);
        console.log("pathLength using Haversine Formula = " + pathlength + " m");
        console.log("pathLength using Gateway Formula = " + pathlengthGateway + " m");
    }
}
