/* global Plotly:true */
import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);

class PluvioAllInOneChartTime extends React.Component {

    constructor(props) {
        super(props);

        // compute the dose and display the pluviometry chart
        let dose = props.pathObj.computeDose();

        var x_data = props.pathObj.date;

        // Dose
        // This one is yet to be computed...
        /*
        var trace1 = {
        	
        };
        */

        // Pressure
        var trace2 = {
            x: x_data,
            y: props.pathObj.pressure,
            line: { shape: 'spline' },
            type: 'scatter',
            mode: 'lines',
            name: 'Pressure(psi)',
            line: {
                width: 2,
                color: 'rgb(255, 100, 0)', // ORANGE
            }
        };

        // Speed
        var trace3 = {
            x: x_data,
            y: props.pathObj.speed,
            line: { 'shape': 'spline', 'smoothing': 2.0 },
            type: 'scatter',
            mode: 'lines',
            name: 'Speed(m/h)',
            //yaxis: 'y3',
            line: {
                width: 2,
                color: 'rgb(0, 128, 0)', // GREEN
            }
        };

        var allinone_data_time = [ /*trace1,*/ trace2, trace3];

        var allinone_layout_time = {

            showlegend: true,
            legend: {
                x: 1,
                xanchor: 'left',
                y: 0.5
            },
            title: '<b>Données de trajet vs Heure</b><br><br>Volume: ' + (dose.vol) + 'm³',
            height: 1000,

            xaxis: {
                showline: true,
                title: "time",
                type: 'date',
                tickformat: '%H:%M',
                tickangle: -90,
                dtick: 15 * 60 * 1000, // tick on x-axis every 15 minutes
                domain: [0, 0.95]
            },

            // Dose axis
            yaxis: {
                showline: true,
                //title: "mm",
                side: 'right',
                rangemode: 'tozero',
                autorange: true,
                //position: 0.05
                position: 0.95
            },
        }

        const plotJSON = {
            data: allinone_data_time,
            layout: allinone_layout_time
        };

        this.state = {
            json: plotJSON
        };
    }

    render() {
        return (<div>
            <Plot
                data={this.state.json.data}
                layout={this.state.json.layout}
                style={{ width: "100%", height: "100%" }}
                config={{ displayModeBar: true }}
            />
        </div>);
    }
}

export default PluvioAllInOneChartTime;