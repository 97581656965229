import { create, all } from 'mathjs';

const config = { };
const math = create(all, config);
/*  a class for digital signal processing along with a few NimbleLink GPS format conversion fucntions
    requires math.js library  to be loaded for matrix functions
*/
export class dsp {
    
        // nothing to initialize in this object,
        // we only use it for its static class functions

    static dateFromGPS(utc, date) {
        var hours = Number(utc.slice(0, 2));
        var minutes = Number(utc.slice(2, 4));
        var seconds = Number(utc.slice(4, 6));
        var msec = Number(utc.slice(7,10));
        var day = Number(date.slice(0,2));
        // careful! javascript months are from (0-11) gps from (1-12)
        var month = Number(date.slice(2,4))-1;
        var year = 2000 + Number(date.slice(4,6));
    
        var d = new Date();
        d.setUTCFullYear(year, month, day);
        d.setUTCHours(hours);
        d.setUTCMinutes(minutes);
        d.setUTCSeconds(seconds);
        d.setUTCMilliseconds(msec);
    
        return d;
    }
    
    static latLongFromGPS(pos_gps) {
        // gps.lat = ddmm.mmmm N/S
        let deg = Number(pos_gps.latitude.slice(0, -8));
        let min = Number(pos_gps.latitude.slice(2, -1));
        let lat = deg + min/60;
        if (pos_gps.latitude.match('S')) {
            lat = -lat;    
        }
    
        // gps.long = dddmm.mmmm E/W
        deg = Number(pos_gps.longitude.slice(0, -8));
        min = Number(pos_gps.longitude.slice(3, -1));
        let long = deg + min/60;
        if (pos_gps.longitude.match('W')) {
            long = -long   
        }
    
        return {
            latitude: lat,
            longitude: long
        }        
    }

    // distance using haversine formula
    // https://www.movable-type.co.uk/scripts/latlong.html
    static distanceLatLong(pos1, pos2) {

            var R = 6371e3; // metres
            var φ1 = pos1.latitude * Math.PI / 180;
            var φ2 = pos2.latitude * Math.PI / 180;
            var Δφ = (pos2.latitude-pos1.latitude) * Math.PI / 180;
            var Δλ = (pos2.longitude-pos1.longitude) * Math.PI / 180;

            var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                    Math.cos(φ1) * Math.cos(φ2) *
                    Math.sin(Δλ/2) * Math.sin(Δλ/2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

            var d = R * c;

            return d;
    }

    /* The distance formula as coded in the gateway */
    static distanceLatLongGateway(pos1, pos2) {
        const CTE_LAT_VARIATION_METERS = 111151.63;  // meters per degree of variation
        const CTE_LONG_VARIATION_METERS = 77438.79;   // meters per degree of variation
        
        let A = (pos1.latitude - pos2.latitude) * CTE_LAT_VARIATION_METERS;
        let B = (pos1.longitude - pos2.longitude) * CTE_LONG_VARIATION_METERS;
        var dist = 0;

        // Using Pythagorus:
        if( (pos1.latitude == pos2.latitude) && (pos1.longitude == pos2.longitude) ) {
            dist = 0;
        }
        else
        {
            dist = Math.sqrt((A*A)+(B*B));
        }
        return dist;
    }

	/* return a gps point on the line between pos1 and pos2;
	   the distance from the returned point to pos1 is 
	   (percent x TotalDistance), where 0% is pos1 and
	   100% is pos2. */
	static percentLatLong(pos1, pos2, percent) {
		var point = {...pos1};
		let lat_var = pos2.latitude - pos1.latitude;
		let long_var = pos2.longitude - pos1.longitude;
		
		point.latitude = (pos1.latitude + ((percent/100.0) * lat_var));
		point.longitude = (pos1.longitude + ((percent/100.0) * long_var));
		
		return point;
	}

	/* return the center position between two gps points 
	   NOTE: this function is equivalent to percentLatLong()
	   with "percent" parameter set to "50". */
	static centerLatLong(pos1, pos2) {
		var center = {...pos1};

		center.latitude += pos2.latitude;
		center.latitude /= 2;
		center.longitude += pos2.longitude;
		center.longitude /= 2;
		
		return center;
	}	
	
	/* Return the matching score of the passed track over the passed area. */
	static validateTrackOverArea(track_startpos, track_endpos, area_startpos, area_endpos, tolerance_m) {
		var area_pos;
		var track_centerpos = dsp.centerLatLong(track_startpos, track_endpos);
		var distance;
		var match_score = 0;
		
		//var dist_array = [];
		
		// Match Criteria: the center point of the passed track must be within
		//                 the given tolerance (m) of the line given by "area_startpos" to 
		//                 "area_endpos". First 20% of the area (both sides) is discarded
		//                 to prevent false matches when tracks are going in an opposite
		//                 direction.
		//
		// Return value: "0" means "no match", a higher value means better match.
		
		for (let j=20;j<=80;j++)
		{
			area_pos = dsp.percentLatLong(area_startpos, area_endpos, j);
			distance = dsp.distanceLatLong(track_centerpos, area_pos);
			
			if(distance <= tolerance_m)
			{
				match_score++;
			}
		}
				
		return match_score;
	}
	
    // extract a polynomial curve of the specified order from given xy data
    static polyfit(xy_data, order) {
        var xMatrix = [];
        for (let j=0;j<xy_data.x.length;j++)
        {
            let xTemp = [];
            for(let i=0;i<=order;i++)
            {
                xTemp.push(1*Math.pow(xy_data.x[j],i));
            }
            xMatrix.push(xTemp);
        }

        var yMatrix = math.transpose(xy_data.y);
        var xMatrixT = math.transpose(xMatrix);
        var dot1 = math.multiply(xMatrixT, xMatrix);
        var dotInv = math.inv(dot1);
        var dot2 = math.multiply(xMatrixT, yMatrix);
        var solution = math.multiply(dotInv,dot2);

        return solution;
    }

    // evaluate y=f(x) using a fitted polynomial
    static polyval(x, poly) {
        let y = [];
        for (let i in x) {
            y[i] = 0;
            for (let j=poly.length-1; j>=0; j--) {
                y[i] = y[i] + poly[j]*Math.pow(x[i],j);
            }
        }
        return y;
    }
    
    // interpolate between two points
    static linearInterpolation (x, x0, y0, x1, y1) {
        var a = (y1 - y0) / (x1 - x0);
        var b = -a * x0 + y0;
        return a * x + b;
    }

    // y = linspace(x1,x2,n) generates n points. The spacing between the points is (x2-x1)/(n-1).
    static linspace(x1, x2, n) {
        var interval = (x2 - x1)/(n-1);
        var val = x1;
        var y = [];
        for (let i=0; i<n; i++) {
            y.push(val);
            val += interval;
        }
        return y;
    }

    // create a vector of all identical values
    static vectOfVal(val, n) {
        var y=[];
        for (let i=0; i<n; i++) {
            y.push(val);
        }
        return y;
    }

    // convolution of two vectors for digital filtering applications
    static conv(a, b, mode) {

        var result = [];
        for (let i=0; i<a.length; i++) {
            for (let j=0; j<b.length; j++) {
                let mult = a[i]*b[j];
                if ( (i+j)<result.length ) {
                    result[i+j] += mult;
                }
                else {
                    result.push(mult);
                }
            }
        }
        // fix result to match original length of vector b
        if (mode == "same_b") {
            return result.slice(-b.length);
        }

        // fix result to match original length of vector a
        if (mode == "same_a") {
            return result.slice(0, a.length);
        }

        return result;
    }

    // find points that change in data
    static find_changing_points(x) {
        // always push first index
        var idx = [0];

        var i,j =0;
        // skip while data is repeated
        for (j=0, i=1; i<x.length; i++, j++) {
            if (x[i] != x[j]) {
                idx.push(i);
            }
        }
        // always push last index replacing another if necessary
        idx.pop();
        idx.push(x.length-1);
        return idx;
    }

    // find points that change in data, but reissue a point if it hasn't changed within Tmax
    static find_inflexion_points(t, x, Tmax) {
        // always push first index
        let idx = [0];
        let prev_t = t[0];

        var i,j = 0;
        // skip while data is repeated
        for (j=0, i=1; i<x.length-2; i++, j++) {
            if (x[i] != x[j]) {
                // if we exceeded the sampling time also keep previous sample
                if ((t[i] - prev_t) >= Tmax) {
                    idx.push(j);
                }
                idx.push(i);
                prev_t = t[i];
            }
        }
        // always push last index
        idx.push(x.length-1);
        return idx;
    }

    // get rid of data not in index list
    static keep_elements(a, idx) {
        var b = [];
        for (let i=0; i<idx.length; i++) {
            b.push(a[idx[i]]);
        }
        return b;
    }

    // get rid of data not in index but but if a zero occurs in a large skip, use it as the data (stop for the night, restart in morning case)
    static keep_elements_withzero(a, idx, timeStamp, Tmax) {
        var b = [];
        var deltaT = 0;
        for (let i=0; i<idx.length; i++) {

            if (i<idx.length-1) {
                deltaT = (timeStamp[idx[i+1]] - timeStamp[idx[i]]);
            }

            // catch big time interval!
            if (deltaT > Tmax) {
                // scan for zero
                for (let j=idx[i]; j<idx[i+1]; j++) {

                    // if we find one, use it for start and finish points
                    if (a[j] == 0) {
                        b.push(0);
                        b.push(0);
                        i++;
                        break 
                    }
                }
                // didn't find one so standard treatment
                b.push(a[idx[i]]);
            }

            else {
                b.push(a[idx[i]]);
            }
        }
        return b;
    }

    // take an asynchrounous time signal and resample it on a fixed period timebase
    static resample_async(ta, a, Tsamp) {
        let ib = [ 0 ];
        let tb = [ ta[0] ];
        let b = [ a[0] ];

        let acc_t = tb[0];
        for (let i=1; i<a.length; i++) {
            for(; acc_t < ta[i]; acc_t +=Tsamp) {
                ib.push(i);
                tb.push(acc_t);
                b.push(a[i]);
            }    
        }
        return { index:ib, time:tb, data:b };
    }

    static interp(xp, yp, item, mode) {

        let x=xp;
        let y=yp;
        let xval=item; 

        // map appropriately for monotonic down by making x negative
        if (xp[0] > xp[xp.length-1]) {
            x=xp.map(val => -val);
            xval=-item;
        }

        // interpolation case
        for (let i=0, j=1; j<x.length; i++, j++) {
            if ((x[i] <= xval) && (x[j] >= xval)) {
                return dsp.linearInterpolation(xval, x[i], y[i], x[j], y[j]);
            }
        }

        // extrapolate before
        if (xval < x[0]) {
            if (mode == "sticky") {
                return y[0];
            }
            return dsp.linearInterpolation(xval, x[0], y[0], x[1], y[1]);    
        }

        // extrapolate beyond
        let end = x.length-1;
        if (xval > x[end]) {
            if (mode == "sticky") {
                return y[end];
            }
            return dsp.linearInterpolation(xval, x[end-1], y[end-1], x[end], y[end]);    
        }

        // out of range
        return NaN;
    }

    // yi = interp1 (x, y, xi) Interpolates input data x,y to determine the value of yi at the points xi.
    static interp1(x, y, xi, mode) {
        // note the function y=f(x) must be monotonic for this to work 
        var yi = [];
        yi = xi.map(val => dsp.interp(x, y, val, mode));
        
        return yi;
    }

    /* compute a scaled vector whose sum of elements is normalized to one */
    static normalize(x) {
        let sum=0;
        let y =[];
        for (let elem of x) {
            sum += Math.abs(elem);
        }
        for (let elem of x) {
            y.push(elem/sum);
        }
        return y;
    }

    /* calculate the differential Area for a pie slice of φ radians with x-axis down the middle */
    static h2oPattern(φjet, x, h) {
        var r = [];
        let φ = φjet/2;
        let hsquared = Math.pow(h,2);
        let tanφ = Math.tan(φ);
        let tp = h*Math.cos(φ);

        for (let val of x) {
            if ((val < tp) && (tp > 0.5)) {
                r.push(val*tanφ);
            }
            else if (val >= h) {
                r.push(0);
            }
            else {
                r.push(Math.sqrt(hsquared -Math.pow(val,2)));
            }
        }
        return r;
    }
	
	static truncateDecimals(value, nbDecimals) {
		
		var multiplicant = 1;
		
		for(let i=0; i<nbDecimals; i++)
		{
			multiplicant *= 10;
		}
		
		value = Math.round( value * multiplicant );
		value /= multiplicant;
    
        return value;
    }	

}
