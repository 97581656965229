import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import ButtonI18n from './i18n/ButtonI18n';
import { Messages } from 'primereact/messages';
import NozzleService from '../service/NozzleService';
import UserService from '../service/UserService';
import LoadingSpinner from './LoadingSpinner';

class AddNozzle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            name: '',
            pressure_psi: '',
            jetlength_m: '',
            debit_m3_per_h: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nozzleService = new NozzleService();
        this.userService = new UserService();
    }

    handleSubmit(event) {

        this.setState({ saving: true });

        this.userService.getCurrentUser()
            .then(async user => {
                let nozzle = {
                    userNozzleId: user.nozzles ? (user.nozzles.reduce((a, b) => Math.max(a, b.userNozzleId), 0) + 1) : 1,
                    name: this.state.name,
                    pressure_psi: this.state.pressure_psi.split(',').map(v => parseFloat(v)),
                    jetlength_m: this.state.jetlength_m.split(',').map(v => parseFloat(v)),
                    debit_m3_per_h: this.state.debit_m3_per_h.split(',').map(v => parseFloat(v))
                }
                let savedNozzle = await this.nozzleService.saveCustomNozzle(nozzle);
                savedNozzle.userNozzleId = nozzle.userNozzleId;
                if (!user.nozzles) {
                    user.nozzles = [];
                }
                user.nozzles.push(savedNozzle);
                await this.userService.save(user);

                this.messages.clear();
                this.messages.show({ severity: 'info', summary: 'Success', detail: '', sticky: true });
                this.setState({
                    saving: false,
                    name: '',
                    pressure_psi: '',
                    jetlength_m: '',
                    debit_m3_per_h: ''
                });
            })
            .catch(error => {
                this.messages.show({ severity: 'error', summary: 'ERREUR', detail: error.message, sticky: true });
                this.setState({ saving: false });
            });
        event.preventDefault();
    }

    render() {

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <LoadingSpinner rendered={this.state.saving} i18nId="spinner.saving" />
                        <Messages ref={(el) => this.messages = el} />
                        <form onSubmit={this.handleSubmit}>
                            <p className="p-float-label">
                                <InputText id="name" value={this.state.name} onChange={event => this.setState({ name: event.target.value })} />
                                <label htmlFor="name">Name</label>
                            </p>
                            <p className="p-float-label">
                                <InputText id="pressure_psi" value={this.state.pressure_psi} onChange={event => this.setState({ pressure_psi: event.target.value })} />
                                <label htmlFor="pressure_psi">Pressures (psi)</label>
                            </p>
                            <p className="p-float-label">
                                <InputText id="jetlength_m" value={this.state.jetlength_m} onChange={event => this.setState({ jetlength_m: event.target.value })} />
                                <label htmlFor="jetlength_m">Jet length (m)</label>
                            </p>
                            <p className="p-float-label">
                                <InputText id="debit_m3_per_h" value={this.state.debit_m3_per_h} onChange={event => this.setState({ debit_m3_per_h: event.target.value })} />
                                <label htmlFor="debit_m3_per_h">Debit (m³/h)</label>
                            </p>

                            <ButtonI18n i18nId="commons.save" defaultMessage="Save" icon="pi pi-check" onClick={this.handleSubmit} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddNozzle;