import { dsp } from '../src/dsp.js'

export class charte {
    
    constructor(rawChartData) {
        // create a new object for storing important data
        this.chart = [];
        for( let i in rawChartData) {
            this.chart.push(this.loadEntry(rawChartData[i]));    
        }
    }

    loadEntry(rawEntry) {
        // initialize chart using available raw data
        var chartEntry = {};
        var data = {};

        // convert pressure value to PSI as needed
        if ("pressure_psi" in rawEntry) {
            data.x = rawEntry.pressure_psi;
        }
        else if ("pressure_bar" in rawEntry) {
            data.x = rawEntry.pressure_bar.map(x => 14.5*x);
        }
        else if ("pressure_kg_per_cm2" in rawEntry) {
            data.x = rawEntry.pressure_kg_per_cm2.map(x => 14.2233*x);
        }

        // chart polynomial for computing jetlenth from psi
        if ("jetlength_m" in rawEntry) {
            data.y = rawEntry.jetlength_m;
            chartEntry.jetlength_m = dsp.polyfit(data, 2);
        }

        // chart polynomial for computing debit from psi
        if ("debit_m3_per_h" in rawEntry) {
            data.y = rawEntry.debit_m3_per_h;
            chartEntry.debit_m3_per_h = dsp.polyfit(data, 2);
        }

        return chartEntry;
    }

    computeJetLength(nozzle, pressure_psi) {
        let poly = this.chart[nozzle].jetlength_m;
        let jetLength_m = dsp.polyval(pressure_psi, poly);
        
        return jetLength_m;
    }

    computeDebit(nozzle, pressure_psi) {
        let poly = this.chart[nozzle].debit_m3_per_h;
        let debit_m3_per_h = dsp.polyval(pressure_psi, poly);

        return debit_m3_per_h;
    }
}