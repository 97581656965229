import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
    idle: {
        id: "state.resolver.idle",
        defaultMessage: "Standby"
    },
    waitingGPS: {
        id: "state.resolver.waitingGPS",
        defaultMessage: "Acquiring GPS"
    },
    readyToStart: {
        id: "state.resolver.readyToStart",
        defaultMessage: "Ready to irrigate"
    },
    running: {
        id: "state.resolver.running",
        defaultMessage: "Irrigating"
    },
    closeToEnd: {
        id: "state.resolver.closeToEnd",
        defaultMessage: "Close to END"
    },
    NA: {
        id: "state.resolver.NA",
        defaultMessage: "N/A"
    }
});

class StatesResolver extends Component {

    resolve(state) {
        const { formatMessage } = this.props.intl;
        switch (state) {
            case 0:
                return formatMessage(messages.idle); // idle
            case 1:
                return formatMessage(messages.waitingGPS); // Waiting GPS
            case 2:
                return formatMessage(messages.readyToStart); // Ready to start
            case 3:
                return formatMessage(messages.running); // Running
            case 4:
                return formatMessage(messages.closeToEnd); // Close to end
            default:
                return formatMessage(messages.NA); // N/A
        }
    }

    render() {
        return (
            <div>{this.resolve(this.props.state)}</div>
        );
    }
}

export default injectIntl(StatesResolver);